import { useEffect, useState } from "react";

export const TAILWIND_XL_W_PX = 1280 as number;

export const useResolution = () => {
  const [resolution, setResolution] = useState(window.innerWidth);

  const updateMedia = () => {
    setResolution(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return resolution;
}