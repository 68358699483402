"use client"

import {
  Briefcase,
  Building,
  ChevronDown,
  Clock,
  Filter,
  Heart,
  MapPin,
  Rocket,
  Search,
  Users
} from "lucide-react";
import { useState } from "react";
import Button from "../../components/button/button.component";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { jobListings } from "../../services/jobs.service";

const departments = ["All Departments", "Engineering", "Design", "Infrastructure", "Marketing", "Sales"];
const locations = ["All Locations", "Sofia", "Remote"];
const jobTypes = ["All Types", "Full-time", "Part-time", "Contract"];

const CareersPage = () => {
  useResetScroll();
  useTitle("Careers");

  const [selectedJob, setSelectedJob] = useState(jobListings.length ? jobListings[0] : null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    department: "All Departments",
    location: "All Locations",
    type: "All Types",
  });

  const filteredJobs = jobListings.filter((job) => {
    const matchesSearch =
      job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.description.toLowerCase().includes(searchTerm.toLowerCase())

    const matchesDepartment = filters.department === "All Departments" || job.department === filters.department;
    const matchesLocation = filters.location === "All Locations" || job.locations.includes(filters.location);
    const matchesType = filters.type === "All Types" || job.type === filters.type;

    return matchesSearch && matchesDepartment && matchesLocation && matchesType;
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-gradient-to-r from-black to-gray-800 text-white pt-20 rounded-b-2xl">
        <div className="container mx-auto px-4 py-20">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Join Our Team</h1>
          <p className="text-xl md:text-2xl max-w-2xl mb-8">
            We're building a team of talented individuals who are passionate about creating innovative products and experiences. If you're looking for a challenging and rewarding career, we'd love to hear from you.
          </p>
          <Button
            click={() => document.getElementById("openings")?.scrollIntoView({ behavior: "smooth" })}
            className="bg-white text-black px-6 py-3 rounded-full font-medium hover:bg-gray-50 transition-colors"
          >
            View Open Positions
          </Button>
        </div>
      </div>

      {/* Job Listings */}
      <div id="openings" className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12">Open Positions</h2>

        {jobListings.length === 0 ? (
          <div className="bg-white p-12 rounded-lg border border-gray-200 text-center max-w-3xl mx-auto">
            <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <Briefcase className="text-gray-400" size={32} />
            </div>
            <h3 className="text-2xl font-semibold mb-3">No Open Positions</h3>
            <p className="text-gray-600 mb-6">
              We don't have any open positions at the moment. Please check back later or follow us on social media for
              announcements.
            </p>
          </div>
        ) : (
          <>
            {/* Search and Filters */}
            <div className="mb-8">
              <div className="flex flex-col md:flex-row gap-4 mb-4">
                <div className="relative flex-grow">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="text"
                    placeholder="Search positions..."
                    className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-full focus:ring-2 focus:ring-black focus:border-black outline-none"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button
                  className="flex items-center gap-2 px-4 py-3 bg-white border border-gray-300 rounded-full hover:bg-gray-50 md:w-auto"
                  onClick={() => document.getElementById("filters")?.classList.toggle("hidden")}
                >
                  <Filter size={20} />
                  <span>Filters</span>
                  <ChevronDown size={16} />
                </button>
              </div>

              {/* Expandable Filters */}
              <div id="filters" className="hidden bg-white p-4 rounded-lg border border-gray-300 mb-4">
                <div className="grid md:grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Department</label>
                    <select
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={filters.department}
                      onChange={(e) => setFilters({ ...filters, department: e.target.value })}
                    >
                      {departments.map((dept) => (
                        <option key={dept} value={dept}>
                          {dept}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Location</label>
                    <select
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={filters.location}
                      onChange={(e) => setFilters({ ...filters, location: e.target.value })}
                    >
                      {locations.map((loc) => (
                        <option key={loc} value={loc}>
                          {loc}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Job Type</label>
                    <select
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={filters.type}
                      onChange={(e) => setFilters({ ...filters, type: e.target.value })}
                    >
                      {jobTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Job List and Details */}
            <div className="grid md:grid-cols-3 gap-6">
              {/* Job List */}
              <div className="md:col-span-1 space-y-4">
                {filteredJobs.length > 0 ? (
                  filteredJobs.map((job) => (
                    <div
                      key={job.id}
                      className={`p-4 rounded-lg cursor-pointer transition-colors ${selectedJob?.id === job.id
                        ? "bg-gray-50 border-2 border-black"
                        : "bg-white border border-gray-200 hover:border-gray-300"
                        }`}
                      onClick={() => setSelectedJob(job)}
                    >
                      <h3 className="font-semibold text-lg">{job.title}</h3>
                      <div className="flex flex-wrap gap-x-4 gap-y-2 mt-2 text-sm text-gray-600">
                        <div className="flex items-center gap-1">
                          <Briefcase size={16} />
                          <span>{job.department}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <MapPin size={16} />
                          <span>{job.locations.join(", ")}</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Clock size={16} />
                          <span>{job.posted}</span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="bg-white p-6 rounded-lg border border-gray-200 text-center">
                    <p className="text-gray-500">No jobs match your search criteria.</p>
                    <button
                      className="mt-2 text-black hover:underline"
                      onClick={() => {
                        setSearchTerm("")
                        setFilters({
                          department: "All Departments",
                          location: "All Locations",
                          type: "All Types",
                        })
                      }}
                    >
                      Clear filters
                    </button>
                  </div>
                )}
              </div>

              {/* Job Details */}
              {selectedJob && (
                <div className="md:col-span-2 bg-white p-6 rounded-lg border border-gray-200">
                  <h2 className="text-2xl font-bold mb-2">{selectedJob.title}</h2>
                  <div className="flex flex-wrap gap-x-4 gap-y-2 mb-6 text-gray-600">
                    <div className="flex items-center gap-1 has-tooltip">
                      <span className="custom-tooltip -mt-14 ml-0">Department</span>
                      <Briefcase size={18} />
                      <span>{selectedJob.department}</span>
                    </div>
                    <div className="flex items-center gap-1 has-tooltip">
                      <span className="custom-tooltip -mt-14 ml-0">Location</span>
                      <MapPin size={18} />
                      <span>{selectedJob.locations.join(", ")}</span>
                    </div>
                    <div className="flex items-center gap-1 has-tooltip">
                      <span className="custom-tooltip -mt-14 ml-0">Type of Engagement</span>
                      <Clock size={18} />
                      <span>{selectedJob.type}</span>
                    </div>
                  </div>

                  <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">About the Role</h3>
                    <p className="text-gray-700 mb-4">{selectedJob.description}</p>

                    <h3 className="text-lg font-semibold mb-2">Requirements</h3>
                    <ul className="list-disc pl-5 space-y-1 text-gray-700">
                      {selectedJob?.requirements?.map((req: any, index: number) => (
                        <li key={index}>{req}</li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex justify-center">
                    <a
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer">
                      <Button type="contrast" className="px-3">
                        <Rocket size={20} />
                        Apply for this Position
                      </Button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="container mx-auto px-2 mb-6">
        <div className="bg-gray-900 text-white py-16 rounded-xl">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Why Work With Us</h2>
            <div className="grid md:grid-cols-4 gap-8">
              <div className="text-center">
                <div className="w-16 h-16 bg-black rounded-full flex items-center justify-center mx-auto mb-4">
                  <Users className="text-white" size={28} />
                </div>
                <h3 className="text-xl font-semibold mb-2">Great Team</h3>
                <p className="text-gray-300">Work with talented, collaborative people who share your passion.</p>
              </div>
              <div className="text-center">
                <div className="w-16 h-16 bg-black rounded-full flex items-center justify-center mx-auto mb-4">
                  <Rocket className="text-white" size={28} />
                </div>
                <h3 className="text-xl font-semibold mb-2">Growth Opportunities</h3>
                <p className="text-gray-300">Continuous learning and career advancement paths for everyone.</p>
              </div>
              <div className="text-center">
                <div className="w-16 h-16 bg-black rounded-full flex items-center justify-center mx-auto mb-4">
                  <Heart className="text-white" size={28} />
                </div>
                <h3 className="text-xl font-semibold mb-2">Work-Life Balance</h3>
                <p className="text-gray-300">Flexible schedules and policies that respect your personal time.</p>
              </div>
              <div className="text-center">
                <div className="w-16 h-16 bg-black rounded-full flex items-center justify-center mx-auto mb-4">
                  <Building className="text-white" size={28} />
                </div>
                <h3 className="text-xl font-semibold mb-2">Meaningful Work</h3>
                <p className="text-gray-300">Build products that make a real difference in people's lives.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareersPage;
