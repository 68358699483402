import { Link } from "react-router-dom"
import Button from "../button/button.component";

const JumbotronSignUpComponent = () => {
  return (
    <section className="webb-pattern-bg rounded-lg py-12 bg-sky-700 text-white text-center mb-6 w-[97%] mx-auto">
      <div className="container mx-auto px-6 max-w-screen-xl">
        <h2 className="text-3xl font-extrabold mb-4">
          Get Started with ReadPDF.io
        </h2>
        <p className="mb-6">
          Transform the way you study! Sign up today and start converting
          your PDFs into high-quality audio.
        </p>
        <Link to="/authenticate">
          <Button>
            Sign Up Now
          </Button>
        </Link>
      </div>
    </section>
  )
};

export default JumbotronSignUpComponent;