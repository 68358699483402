export const jobListings: any[] = [];
const _jobListings = [
  {
    id: 1,
    title: "Senior Frontend Developer",
    department: "Engineering",
    locations: ["Sofia"],
    type: "Full-time",
    description:
      "We're looking for an experienced frontend developer to join our team and help build amazing user experiences.",
    requirements: [
      "5+ years of experience with React",
      "Strong knowledge of modern JavaScript",
      "Experience with responsive design and CSS frameworks",
      "Familiarity with state management solutions",
    ],
    posted: "2 days ago",
  },
  {
    id: 2,
    title: "Product Designer",
    department: "Design",
    locations: ["Sofia", "Remote"],
    type: "Contract",
    description: "Join our design team to create beautiful and intuitive interfaces for our products.",
    requirements: [
      "3+ years of product design experience",
      "Proficiency in Figma and design systems",
      "Understanding of user-centered design principles",
      "Experience collaborating with engineering teams",
    ],
    posted: "1 week ago",
  },
  {
    id: 3,
    title: "DevOps Engineer",
    department: "Infrastructure",
    locations: ["Sofia", "Remote"],
    type: "Contract",
    description: "Help us build and maintain our cloud infrastructure and deployment pipelines.",
    requirements: [
      "Experience with AWS or GCP",
      "Knowledge of containerization and Kubernetes",
      "Familiarity with CI/CD pipelines",
      "Understanding of infrastructure as code",
    ],
    posted: "3 days ago",
  },
  {
    id: 4,
    title: "Marketing Specialist",
    department: "Marketing",
    locations: ["Sofia", "Remote"],
    type: "Contract",
    description: "Drive our marketing efforts and help us reach new customers.",
    requirements: [
      "3+ years of B2B marketing experience",
      "Experience with digital marketing campaigns",
      "Strong analytical skills",
      "Excellent communication abilities",
    ],
    posted: "5 days ago",
  },
];