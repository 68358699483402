import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import './authenticate-routes.styles.scss';

const AuthenticateRoutes = () => {
  const { user, loading } = useCombinedAuthHook();
  const [loadingTimeout, setLoadingTimeout] = useState(true);
  const [loadingFinished, setLoadingFinished] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingTimeout(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading && !loadingTimeout) {
      setLoadingFinished(true);
    }
  }, [loading, loadingTimeout]);

  if (!loadingFinished) {
    return (
      <div className="h-screen flex items-center justify-center text-center loading-page">
        <div className="relative flex flex-col align-center justify-center">
          <span className="loader mr-10 mb-4 self-center"></span>
          <p className="text-6xl blue-800 font-bold">
            ReadPDF.io
          </p>
        </div>
      </div>
    );
  }

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default AuthenticateRoutes;
