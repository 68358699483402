const sendEmail = async (authToken: Promise<string | undefined>, uid: string) => {
  const formData = new FormData();
  formData.append("uid", uid);

  return await fetch(`${process.env.REACT_APP_APPLICATION_URL}/account/verify`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${await authToken}`
    }
  });
};

export default sendEmail;
