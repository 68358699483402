"use client"

import { useState, useEffect } from "react";
import Button from "../button/button.component";
import { Cookie, X } from "lucide-react";

const CookiesModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasConsented = localStorage.getItem("cookieConsent");

    if (!hasConsented) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  }

  const handleClose = () => {
    setIsVisible(false);
  }

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50 max-w-xs">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-4 pb-2">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">
              <Cookie className="h-6 w-6 inline-block mr-2" />
              Cookies</h3>
            <button
              className="p-1 rounded-full"
              onClick={handleClose}
              aria-label="Close"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
        <div className="px-4 pb-4 text-sm text-gray-600">
          <p>
            We use cookies to enhance your browsing experience, and analyze our traffic. By using our service, you consent to our use of cookies.
          </p>
        </div>
        <div className="px-4 pb-4 text-center">
          <Button
            type="contrast"
            click={handleConsent}
            className="mx-auto"
          >
            Agree
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookiesModal;

