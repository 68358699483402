
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { IoShareOutline } from "react-icons/io5";
import { LuPanelRightClose, LuPanelRightOpen } from "react-icons/lu";
import { useParams, useSearchParams } from "react-router-dom";
import PlayerTrackPageComponent, { MediaSessionMeta } from "../../components/player-track-page/player-track-page.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { tryGetTrackByUserUidAndFileId, tryUpdateName } from "../../hooks/database-file";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { ActiveSubscriptionTypes, Note, UserTracks } from "../../types";
import { decodeString } from "../../utils/crypto-utils";

import { FaPen } from "react-icons/fa6";
import { useRecoilState } from "recoil";
import { TrackNotePaneComponent } from "../../components/player-track-page/track-note-pane/track-note-pane.component";
import { TrackPageSubscribePromptComponent } from "../../components/player-track-page/track-page-subscribe-prompt/track-page-subscribe-prompt.component";
import useDatabaseNote from "../../hooks/database-note.hook";
import { TAILWIND_XL_W_PX, useResolution } from "../../hooks/resolution.hook";
import { showNotesState } from "../../state/show-notes-pane.state";
import "./track.styles.scss";
import NoteModal from "../../components/note-modal/note-modal.component";
import { DirtyNote } from "../notes/notes.page";

const TrackPage = () => {
  useTitle("Listen");
  const { user, subscriptionStatus } = useCombinedAuthHook();
  const { userId, fileId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const [urlCleanUserId] = useState(decodeString(userId));
  const mp3Url = useMemo(() => {
    return `${process.env.REACT_APP_FE_URL}/track/${userId}/${fileId}`;
  }, [userId, fileId]);

  const [timestampsData, setTimestampsData] = useState<any[]>([]);
  const [currentTime, setCurrentTime] = useState(0);

  const [trackNotFound, setTrackNotFound] = useState(true);
  const [stateTrack, setTrack] = useState<UserTracks | null>(null);
  const [trackName, setTrackName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [trackStartTime, setTrackStartTime] = useState(0);
  const [isNotesPaneOpen, setIsNotesPaneOpen] = useRecoilState(showNotesState);
  const [trackMetadata, setTrackMetadata] = useState<MediaSessionMeta | undefined>(undefined);
  const [notes, setNotes] = useState<Note[]>([]);
  const [noteToShow, setNoteToShow] = useState<DirtyNote | undefined>(undefined);
  const [updateNotes, setUpdateNotes] = useState(false);

  const { createNote, getNotesForUserAndTrack, deleteNote } = useDatabaseNote();
  const browserResolution = useResolution();

  useResetScroll();

  const getCurrentWordIndex = (currentTime: number) => {
    const currentTimeMs = currentTime * 1000;
    for (let i = 0; i < timestampsData.length; i++) {
      if (timestampsData[i].time >= currentTimeMs) {
        return i - 1;
      }
    }
    return timestampsData.length - 1;
  };

  const currentWordIndex = getCurrentWordIndex(currentTime);

  const CHUNK_SIZE = 40;
  const chunkNumber = Math.floor(currentWordIndex / CHUNK_SIZE);

  const wordsToDisplay = useMemo(() => {
    const start = chunkNumber * CHUNK_SIZE;
    const end = Math.min(timestampsData.length - 1, start + CHUNK_SIZE - 1);
    return timestampsData.slice(start, end + 1);
  }, [chunkNumber, timestampsData]);

  const tryGetNotes = (trackId: string) => {
    getNotesForUserAndTrack(trackId)
      .then((notes) => {
        setNotes(!!notes ? Object.values(notes) : []);
      })
      .catch((e) => {
        toast.error("Error fetching notes. Please try again.");
      });
  };

  const handleTrackNameSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!value) {
      toast.error("You can't have an empty track name.");
      return;
    }

    setIsEditing(false);

    if (stateTrack?.trackUid === value) {
      return;
    }

    if (!isUserTheTrackOwner || !fileId) {
      toast.error(
        "There was a problem while updating the name. Please, try again later."
      );
      return;
    }

    try {
      await tryUpdateName(urlCleanUserId, fileId, value);
      toast.success("The track name was updated successfully!");
    } catch (error) {
      toast.error(
        "There was a problem while updating the name. Please, try again later."
      );
    } finally {
      tryGetTrackByUserUidAndFileId(urlCleanUserId, fileId).then(track => {
        if (!track) {
          setTrackNotFound(true);
          return;
        }
        setTrack(track);
        setTrackName(track.trackUid);
        setTrackMetadata((prev) => ({
          title: track.trackUid,
          artist: "ReadPDF.io",
        }));
      });
    }
  };

  useEffect(() => {
    if (userId && fileId) {
      tryGetTrackByUserUidAndFileId(urlCleanUserId, fileId).then(
        (track: UserTracks | null) => {
          if (!track) {
            setTrackNotFound(true);
            return;
          }

          setTrack(track!);
          setTrackNotFound(false);
          setTrackName(track.trackUid);
          tryGetNotes(track.fileId);
          setTrackMetadata((prev) => ({
            title: track.trackUid,
          }));
        }
      );
    }
  }, []);


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleTrackNameSubmit(event as any);
      }
    };

    if (!isEditing) {
      window.removeEventListener("keydown", handleKeyPress);
      return;
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isEditing]);

  useEffect(() => {
    if (updateNotes) {
      tryGetNotes(fileId!);
      setUpdateNotes(false);
    }
  }, [updateNotes]);


  useEffect(() => {
    if (!stateTrack) return;

    if (stateTrack.recordingUrl) {
      const targetSpeechDataEndpoint = stateTrack.recordingUrl.replace(
        ".mp3",
        "_speech_marks.json"
      );
      fetch(targetSpeechDataEndpoint)
        .then((response) => response.json())
        .then((data) => {
          const wordData = data.filter(
            (item: any) =>
              item.type === "word" &&
              !(item.value.includes("<") && item.value.includes(">"))
          );
          setTimestampsData(wordData);
        })
        .catch((_) => { });
    }
  }, [stateTrack]);

  useEffect(() => {
    if (!stateTrack) return;

    const timestamps = stateTrack?.timestamps ?? {};

    const mark = queryParams.get("mark");
    const userTimestamp = user.uid in timestamps ? timestamps[user.uid as any] : null;
    const targetTimestamp = mark ? +mark : userTimestamp?.activeTimestamp;

    if (!targetTimestamp) return;

    setTrackStartTime(targetTimestamp);
  }, [stateTrack]);

  const isUserTheTrackOwner = useMemo(
    () => urlCleanUserId === user.uid,
    [urlCleanUserId, user.uid]
  );

  if (trackNotFound) {
    return <h2>Track not found.</h2>
  }

  const handleTimeUpdate = (time: number) => {
    setCurrentTime(time);
  };

  const handleNotesPaneToggle = () => {
    setIsNotesPaneOpen((prevState) => !prevState);
  };

  const handleAddNote = (newNote: Note) => {
    if (!fileId) {
      toast.error("Cannot add note without a track.");
      return;
    };

    try {
      createNote({ trackId: fileId, note: { ...newNote, trackAuthorId: urlCleanUserId } });
      setNotes((prev) => [...prev, newNote]);
    } catch (e) {
      toast.error("Error adding note. Please try again.");
      return;
    }

    toast.success("Note added", {
      duration: 500,
    });
  };

  const handleRemoveNote = async (id: string) => {
    if (!fileId) {
      toast.error("Cannot remove the note now. Please, try again.");
      return;
    };

    try {
      await deleteNote(urlCleanUserId, fileId, id);
      setNotes((prev) => prev.filter((note) => note.id !== id));
    } catch (e) {
      toast.error("Error removing note. Please try again.");
      return;
    }

    toast.success("Note deleted", {
      duration: 500,
    });
  };

  const handleTrackNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isUserTheTrackOwner) return;
    setTrackName(e.target.value);
  };

  const handleEditClick = () => {
    if (!isUserTheTrackOwner) return;
    setIsEditing(true);
  };

  const jumpToSecond = (time: number) => {
    setTrackStartTime(time);
  };

  const handleShareClick = () => {
    try {
      navigator.clipboard.writeText(mp3Url);
    } catch (e) {
      toast.error("Error copying URL to clipboard. Please try again.");
      return;
    }
    toast.success("URL copied to clipboard!");
  };

  const formatDate = (date?: string) => {
    if (!date) return "";
    const dateFormat = new Date(date);
    return `${dateFormat.toDateString()}, at ${dateFormat.toLocaleTimeString()}`;
  };

  const handleNoteExpandButtonClick = (note: Note) => {
    setNoteToShow({ ...note, trackAuthorId: urlCleanUserId, trackId: fileId! });
  };

  const hi_res = browserResolution > TAILWIND_XL_W_PX;
  const lo_res_open = !hi_res && isNotesPaneOpen;
  const lo_res_closed = !hi_res && !isNotesPaneOpen;

  const notesPaneWidth = hi_res ? 'w-1/3' : lo_res_open ? 'w-1/2' : 'w-1/6';
  const transcriptWidth = hi_res ? 'w-2/3' : lo_res_open ? 'w-1/2' : 'w-5/6';

  const activeWord = wordsToDisplay.map((wordData, index) => {
    const wordIndex = chunkNumber * CHUNK_SIZE + index;
    const isCurrentWord = wordIndex === currentWordIndex;
    const isNextWord =
      wordIndex < currentWordIndex + 4 &&
      wordIndex > currentWordIndex;
    const isPreviousWord =
      wordIndex > currentWordIndex - 4 &&
      wordIndex < currentWordIndex;

    return (
      <span
        key={wordIndex}
        className={
          isCurrentWord
            ? "highlight"
            : isPreviousWord || isNextWord
              ? "light-highlight"
              : ""
        }
      >
        {wordData.value}{" "}
      </span>
    );
  });

  return (
    <>
      {!ActiveSubscriptionTypes.includes(subscriptionStatus) && (
        <TrackPageSubscribePromptComponent />
      )}
      <div className="flex flex-col items-center h-fit md:h-screen sm:p-4">
        <div className={`calculated-height-${ActiveSubscriptionTypes.includes(subscriptionStatus) ? 'subscribed' : 'unsubscribed'} w-full lg:max-w-screen-lg border bg-[#f3f3f3] rounded-lg p-2 sm:p-6 flex flex-col md:justify-between`}>
          <div className="flex-none flex flex-row items-center mb-6 mt-2 gap-4 justify-between">
            <div className="flex-none w-10/12 ">
              <div className="flex items-center">
                {isEditing ? (
                  <input
                    type="text"
                    value={trackName}
                    onChange={(e) => handleTrackNameChange(e)}
                    // onBlur={(e) => handleTrackNameSubmit(e)}
                    className="text-xl p-0 pl-2 border-none rounded border-b-1 border-gray-300"
                    autoFocus
                  />
                ) : (
                  <h2 className="text-xl max-w-11/12 overflow-hidden whitespace-nowrap truncate text-ellipsis">
                    {trackName}
                  </h2>
                )}
                {isUserTheTrackOwner && !isEditing && (
                  <button
                    onClick={handleEditClick}
                    className="ml-2 text-gray-600 has-tooltip"
                  >
                    <span className='custom-tooltip'>Edit name</span>
                    <FaPen size={15} />
                  </button>
                )}
              </div>
              <p className="text-gray-500 text-sm tracking-tight">
                {formatDate(stateTrack?.date)}
              </p>
            </div>
            <button
              onClick={handleShareClick}
              className="flex-none bg-gray-50 hover:bg-gray-100 rounded-xl border p-2 pl-4 pr-4 has-tooltip"
            >
              <span className='custom-tooltip share-tooltip'>Copy link to clipboard</span>
              <IoShareOutline size={16} />
            </button>
          </div>
          <div className="flex-grow flex border px-2 p-1 rounded-xl min-h-0">
            <div className={`flex-none transcript mt-4 pr-3 ${transcriptWidth}`}>
              <h2 className="text-left font-serif text-lg">Live Transcript</h2>
              <p className="text-left mt-4">
                {wordsToDisplay.length ? activeWord :
                  <span className="text-gray-600 text-sm">Play to get live transcript.</span>
                }
              </p>
            </div>
            {notes.length > 0 && (
              <div className={`border-l p-2 flex-none overflow-y-auto min-h-0 ${notesPaneWidth} transition-[width] duration-100`}>
                {!hi_res && (<button
                  onClick={handleNotesPaneToggle}
                  className="flex items-center space-x-1"
                >
                  {isNotesPaneOpen ? <LuPanelRightClose /> : <LuPanelRightOpen />}
                  <div className="flex justify-end gap-2">
                    <span className="hidden md:inline text-sm">Notes</span>
                    {!isNotesPaneOpen && <span className="inline-block rounded-full bg-gray-800 px-2 py-1 text-xs text-white">{notes.length}</span>}
                  </div>
                </button>)}

                {!lo_res_closed &&
                  <TrackNotePaneComponent handleRemoveNote={handleRemoveNote} jumpToSecond={jumpToSecond} notes={notes} expandHandler={handleNoteExpandButtonClick} />
                }
              </div>
            )}
          </div>
          <div className="w-full flex items-center justify-center mt-16 relative self-end">
            {stateTrack && (
              <PlayerTrackPageComponent
                author={urlCleanUserId}
                trackUid={stateTrack.fileId}
                url={stateTrack.recordingUrl}
                startTime={trackStartTime}
                onTimeUpdate={handleTimeUpdate}
                notes={notes}
                onAddNote={handleAddNote}
                onRemoveNote={handleRemoveNote}
                mediaSessionInformation={trackMetadata}
              />
            )}
          </div>
        </div>
      </div>
      <NoteModal activeNote={noteToShow} removeActiveNote={() => setNoteToShow(undefined)} updateNotes={(b) => setUpdateNotes(b)} takeMeToTrackPage={(e) => { setNoteToShow(undefined); }} />
    </>
  );
};

export default TrackPage;
