import { useRef } from "react";

type NoteMarkProps = {
  noteText: string;
  markPostion: number;
  timeStamp: number;
  onMarkClick: (time: number) => void;
};

export const NoteMark = ({
  noteText,
  markPostion,
  timeStamp,
  onMarkClick,
}: NoteMarkProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className="group cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        onMarkClick(timeStamp);
      }}
    >
      <div
        ref={ref}
        className="absolute opacity-50 top-0 w-1 h-2 bg-red-500 transition group-hover:opacity-100"
        style={{ left: `${markPostion}%` }}
      />

      <div className="absolute bottom-full max-w-44 invisible mb-4 w-48 truncate rounded bg-white p-2 text-sm shadow-lg transition inset-shadow-indigo-500/50 group-hover:visible" style={{ left: `${markPostion}%` }}>
        <span className="text-black ">{noteText}</span>
      </div>
      <div
        style={{ left: `${markPostion}%` }}
        className="absolute bottom-full left-0 opacity-0 group-hover:opacity-100 w-1 h-full bg-gradient-to-t from-red-500 to-yellow-500 [clip-path:polygon(0%_100%,100%_100%,50%_0%)]" />
    </div>
  );
};
