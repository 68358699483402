import * as Amplitude from "amplitudejs";
import { TbRewindBackward5, TbRewindForward5 } from "react-icons/tb";
import "./pdf-player.styles.scss";

export type PdfPlayerProps = {
  pdfName: string;
  songIndex: number;
  children: any;
  trailingBadge?: string;
};

const PdfPlayerComponent = ({
  children,
  pdfName,
  songIndex,
  trailingBadge,
}: PdfPlayerProps) => {
  function rewind(target: number) {
    const elapsed = Amplitude.getSongPlayedSeconds();

    if (elapsed + target > 0) {
      Amplitude.skipTo(elapsed + target, songIndex);
    } else {
      Amplitude.skipTo(0, songIndex);
    }
  }

  return (
    <div className="pdf-bg w-60 md:w-48 lg:w-60 h-[350px] py-6 px-3 rounded-xl outline outline-gray-200 outline-offset-4 outline-2 relative mx-3 md:mx-7 my-10 md:my-0">
      <div className="absolute w-fit py-4 lg:px-2 -top-8 -right-5">
        {trailingBadge && (
          <div
            className="py-0.5 pl-2 pr-0 px-1 pb-1 mr-2 bg-orange-600 items-center text-indigo-100 leading-none rounded-full inline-flex"
            role="alert"
          >
            <span className="font-light mr-2 text-left flex-auto text-xs">
              {trailingBadge}
            </span>
          </div>
        )}
        <div
          className="py-0.5 pl-2 px-1 pb-1 bg-slate-600 items-center text-indigo-100 leading-none rounded-full inline-flex"
          role="alert"
        >
          <span className="font-light mr-2 text-left flex-auto text-xs">
            {pdfName}
          </span>
        </div>
      </div>
      <div className="custom-scrollbar relative top-0 bottom-0 left-0 right-0 h-full w-full overflow-y-scroll pr-2">
        {children}
      </div>
      <div className="absolute -bottom-12 flex w-[115%] -left-6 px-3 py-1 justify-center items-center gap-9 border rounded-full bg-white shadow-md">
        <div className="cursor-pointer" onClick={() => rewind(-5)}>
          <TbRewindBackward5 size={24} color="#a2a4a9" />
        </div>
        <div
          className="cursor-pointer amplitude-play-pause w-16 h-16 rounded-full bg-white border border-play-pause-light-border shadow-xl flex items-center justify-center"
          data-amplitude-song-index={songIndex}
        >
          <svg
            id="play-icon"
            className="ml-[10px]"
            width="31"
            height="37"
            viewBox="0 0 31 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.6901 16.6608L4.00209 0.747111C2.12875 -0.476923 0.599998 0.421814 0.599998 2.75545V33.643C0.599998 35.9728 2.12747 36.8805 4.00209 35.6514L29.6901 19.7402C29.6901 19.7402 30.6043 19.0973 30.6043 18.2012C30.6043 17.3024 29.6901 16.6608 29.6901 16.6608Z"
              className="fill-slate-500 dark:fill-slate-400"
            />
          </svg>

          <svg
            id="pause-icon"
            width="24"
            height="36"
            viewBox="0 0 24 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="6"
              height="36"
              rx="3"
              className="fill-slate-500 dark:fill-slate-400"
            />
            <rect
              x="18"
              width="6"
              height="36"
              rx="3"
              className="fill-slate-500 dark:fill-slate-400"
            />
          </svg>
        </div>
        <div className="cursor-pointer" onClick={() => rewind(5)}>
          <TbRewindForward5 size={24} color="#a2a4a9" />
        </div>
      </div>
    </div>
  );
};

export default PdfPlayerComponent;
