import {
  BarChart,
  BookOpen,
  Briefcase,
  CheckCircle,
  Clock,
  Headphones,
  Languages,
  Pause,
  Play,
  User
} from "lucide-react";
import { useState } from "react";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";

const UseCasesPage = () => {
  useTitle("Use Cases");
  useResetScroll();

  const [isPlaying, setIsPlaying] = useState(false)
  const [activeTab, setActiveTab] = useState("business")

  const togglePlay = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* Use Cases Section */}
      <section className="w-full py-12 md:py-24 bg-white mt-20">
        <div className="container px-4 md:px-6 mx-auto max-w-7xl">
          <div className="flex flex-col items-center justify-center space-y-4 text-center mb-12">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-black">Use Cases</h2>
              <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl">
                Discover how our PDF listener can transform the way you consume content
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Use Case 1: On-the-go Learning */}
            <div className="bg-gray-50 rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group">
              <div className="p-8">
                <div className="flex items-start gap-6">
                  <div className="w-16 h-16 rounded-full bg-black flex items-center justify-center flex-shrink-0">
                    <BookOpen className="h-8 w-8 text-white" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold mb-3 text-black group-hover:text-gray-800">On-the-go Learning</h3>
                    <p className="text-gray-600 mb-6">
                      Transform study materials and textbooks into audio for learning while commuting, exercising, or
                      doing chores.
                    </p>
                  </div>
                </div>

                <div className="mt-8 space-y-6">
                  <div className="bg-white p-6 rounded-2xl border border-gray-100">
                    <h4 className="font-semibold mb-3 flex items-center gap-2 text-black">
                      <User className="h-5 w-5" /> Who benefits
                    </h4>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Students with heavy reading loads</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Professionals pursuing continuing education</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Lifelong learners with busy schedules</span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-black/10 flex items-center justify-center">
                      <Clock className="h-6 w-6 text-black" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-black">Save up to 10 hours weekly</h4>
                      <p className="text-sm text-gray-500">By converting reading time to listening time</p>
                    </div>
                  </div>

                  <div className="bg-black/5 p-4 rounded-2xl italic text-gray-600">
                    "I listen to my textbook chapters during my morning commute. By the time I get to class, I'm already
                    prepared for discussion."
                    <div className="mt-2 text-sm font-medium text-black">— Alex, Graduate Student</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Use Case 2: Accessibility */}
            <div className="bg-gray-50 rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group">
              <div className="p-8">
                <div className="flex items-start gap-6">
                  <div className="w-16 h-16 rounded-full bg-black flex items-center justify-center flex-shrink-0">
                    <Headphones className="h-8 w-8 text-white" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold mb-3 text-black group-hover:text-gray-800">Accessibility</h3>
                    <p className="text-gray-600 mb-6">
                      Make documents accessible for users with visual impairments or reading difficulties.
                    </p>
                  </div>
                </div>

                <div className="mt-8 space-y-6">
                  <div className="bg-white p-6 rounded-2xl border border-gray-100">
                    <h4 className="font-semibold mb-3 flex items-center gap-2 text-black">
                      <BarChart className="h-5 w-5" /> Key features
                    </h4>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Natural-sounding voices with minimal robotic tones</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Adjustable reading speeds (0.5x to 3x)</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Text highlighting synced with audio playback</span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-black/10 flex items-center justify-center">
                      <User className="h-6 w-6 text-black" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-black">Supports diverse needs</h4>
                      <p className="text-sm text-gray-500">Dyslexia, visual impairments, and learning differences</p>
                    </div>
                  </div>

                  <div className="bg-black/5 p-4 rounded-2xl italic text-gray-600">
                    "As someone with dyslexia, this app has been life-changing. I can finally keep up with my reading
                    assignments without the usual struggle."
                    <div className="mt-2 text-sm font-medium text-black">— Jamie, College Student</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Use Case 3: Multitasking */}
            <div className="bg-gray-50 rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group">
              <div className="p-8">
                <div className="flex items-start gap-6">
                  <div className="w-16 h-16 rounded-full bg-black flex items-center justify-center flex-shrink-0">
                    <Briefcase className="h-8 w-8 text-white" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold mb-3 text-black group-hover:text-gray-800">Multitasking</h3>
                    <p className="text-gray-600 mb-6">
                      Listen to reports and documents while performing other tasks to maximize productivity.
                    </p>
                  </div>
                </div>

                <div className="mt-8 space-y-6">
                  <div className="bg-white p-6 rounded-2xl border border-gray-100">
                    <h4 className="font-semibold mb-3 flex items-center gap-2 text-black">
                      <User className="h-5 w-5" /> Perfect for
                    </h4>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Busy professionals with limited time</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Executives who need to review multiple reports</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Anyone who wants to maximize productivity</span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-black/10 flex items-center justify-center">
                      <BarChart className="h-6 w-6 text-black" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-black">30% productivity increase</h4>
                      <p className="text-sm text-gray-500">Reported by business users</p>
                    </div>
                  </div>

                  <div className="bg-black/5 p-4 rounded-2xl italic text-gray-600">
                    "I listen to quarterly reports during my morning workout. By the time I reach the office, I'm
                    already up to speed and ready for meetings."
                    <div className="mt-2 text-sm font-medium text-black">— Michael, Marketing Director</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Use Case 4: Language Learning */}
            <div className="bg-gray-50 rounded-3xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group">
              <div className="p-8">
                <div className="flex items-start gap-6">
                  <div className="w-16 h-16 rounded-full bg-black flex items-center justify-center flex-shrink-0">
                    <Languages className="h-8 w-8 text-white" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold mb-3 text-black group-hover:text-gray-800">Language Learning</h3>
                    <p className="text-gray-600 mb-6">
                      Hear pronunciation while following along with text in foreign languages.
                    </p>
                  </div>
                </div>

                <div className="mt-8 space-y-6">
                  <div className="bg-white p-6 rounded-2xl border border-gray-100">
                    <h4 className="font-semibold mb-3 flex items-center gap-2 text-black">
                      <BarChart className="h-5 w-5" /> Language features
                    </h4>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Support for 40+ languages and accents</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Synchronized text highlighting</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="h-5 w-5 text-black mt-0.5 flex-shrink-0" />
                        <span className="text-gray-600">Vocabulary extraction and practice tools</span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-full bg-black/10 flex items-center justify-center">
                      <Clock className="h-6 w-6 text-black" />
                    </div>
                    <div>
                      <h4 className="font-semibold text-black">Learn 2x faster</h4>
                      <p className="text-sm text-gray-500">By combining reading and listening</p>
                    </div>
                  </div>

                  <div className="bg-black/5 p-4 rounded-2xl italic text-gray-600">
                    "I've been learning Spanish, and this app helps me hear proper pronunciation while I read. My
                    comprehension has improved dramatically."
                    <div className="mt-2 text-sm font-medium text-black">— Sarah, Language Enthusiast</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hidden w-11/12 py-12 md:py-24 bg-gray-100 pattern rounded-3xl mx-auto mb-5">
        <div className="container px-4 md:px-6 mx-auto max-w-7xl">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-black">
                See It In Action
              </h2>
              <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl">
                Experience how our PDF listener works with this interactive demo
              </p>
            </div>
          </div>

          <div className="mt-12 mx-auto max-w-4xl">
            {/* Custom Tabs */}
            <div className="w-full">
              <div className="grid w-full grid-cols-4 bg-white rounded-full p-1 border shadow-sm">
                <button
                  className={`py-2 text-sm font-medium rounded-full transition-all ${activeTab === "business" ? "bg-black text-white shadow-md" : "bg-transparent text-gray-700 hover:bg-gray-100"}`}
                  onClick={() => setActiveTab("business")}
                >
                  Business
                </button>
                <button
                  className={`py-2 text-sm font-medium rounded-full transition-all ${activeTab === "education" ? "bg-black text-white shadow-md" : "bg-transparent text-gray-700 hover:bg-gray-100"}`}
                  onClick={() => setActiveTab("education")}
                >
                  Education
                </button>
                <button
                  className={`py-2 text-sm font-medium rounded-full transition-all ${activeTab === "accessibility" ? "bg-black text-white shadow-md" : "bg-transparent text-gray-700 hover:bg-gray-100"}`}
                  onClick={() => setActiveTab("accessibility")}
                >
                  Accessibility
                </button>
                <button
                  className={`py-2 text-sm font-medium rounded-full transition-all ${activeTab === "language" ? "bg-black text-white shadow-md" : "bg-transparent text-gray-700 hover:bg-gray-100"}`}
                  onClick={() => setActiveTab("language")}
                >
                  Language
                </button>
              </div>

              {/* Tab Content */}
              <div className="mt-6">
                {activeTab === "business" && (
                  <div className="border rounded-3xl shadow-sm overflow-hidden bg-white">
                    <div className="p-6">
                      <h3 className="text-lg font-semibold text-black">Quarterly Financial Report</h3>
                      <p className="text-sm text-gray-500 mb-4">Listen to your business documents while multitasking</p>
                      <div className="bg-gray-50 border rounded-2xl p-4 mb-4">
                        <p className="text-sm text-gray-600">
                          In Q3 2023, our company saw a 15% increase in revenue compared to the previous quarter. This
                          growth was primarily driven by our new product line, which exceeded sales expectations by 22%.
                          Operating expenses remained stable at $1.2M, resulting in a profit margin of 28%...
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-4 mt-6">
                        <button
                          className="h-12 w-12 rounded-full bg-black flex items-center justify-center hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 transition-all"
                          onClick={togglePlay}
                        >
                          {isPlaying ? (
                            <Pause className="h-6 w-6 text-white" />
                          ) : (
                            <Play className="h-6 w-6 text-white" />
                          )}
                        </button>
                        <div className="w-full max-w-md">
                          <div className="h-2 bg-gray-200 rounded-full">
                            <div className="h-full w-1/3 bg-black rounded-full"></div>
                          </div>
                          <div className="flex justify-between mt-1 text-xs text-gray-500">
                            <span>1:23</span>
                            <span>4:56</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "education" && (
                  <div className="border rounded-3xl shadow-sm overflow-hidden bg-white">
                    <div className="p-6">
                      <h3 className="text-lg font-semibold text-black">Physics Textbook</h3>
                      <p className="text-sm text-gray-500 mb-4">Study on the go with audio textbooks</p>
                      <div className="bg-gray-50 border rounded-2xl p-4 mb-4">
                        <p className="text-sm text-gray-600">
                          Newton's First Law of Motion states that an object at rest stays at rest, and an object in
                          motion stays in motion with the same speed and direction unless acted upon by an unbalanced
                          force. This property of objects to resist changes in their state of motion is called
                          inertia...
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-4 mt-6">
                        <button
                          className="h-12 w-12 rounded-full bg-black flex items-center justify-center hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 transition-all"
                          onClick={togglePlay}
                        >
                          {isPlaying ? (
                            <Pause className="h-6 w-6 text-white" />
                          ) : (
                            <Play className="h-6 w-6 text-white" />
                          )}
                        </button>
                        <div className="w-full max-w-md">
                          <div className="h-2 bg-gray-200 rounded-full">
                            <div className="h-full w-1/4 bg-black rounded-full"></div>
                          </div>
                          <div className="flex justify-between mt-1 text-xs text-gray-500">
                            <span>0:45</span>
                            <span>3:12</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "accessibility" && (
                  <div className="border rounded-3xl shadow-sm overflow-hidden bg-white">
                    <div className="p-6">
                      <h3 className="text-lg font-semibold text-black">News Article</h3>
                      <p className="text-sm text-gray-500 mb-4">Make content accessible for everyone</p>
                      <div className="bg-gray-50 border rounded-2xl p-4 mb-4">
                        <p className="text-sm text-gray-600">
                          The city council announced today that the new community center will open next month. The $4.2
                          million facility includes a gymnasium, swimming pool, and multipurpose rooms for community
                          events. Mayor Johnson called it "a significant investment in our community's future"...
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-4 mt-6">
                        <button
                          className="h-12 w-12 rounded-full bg-black flex items-center justify-center hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 transition-all"
                          onClick={togglePlay}
                        >
                          {isPlaying ? (
                            <Pause className="h-6 w-6 text-white" />
                          ) : (
                            <Play className="h-6 w-6 text-white" />
                          )}
                        </button>
                        <div className="w-full max-w-md">
                          <div className="h-2 bg-gray-200 rounded-full">
                            <div className="h-full w-1/2 bg-black rounded-full"></div>
                          </div>
                          <div className="flex justify-between mt-1 text-xs text-gray-500">
                            <span>1:05</span>
                            <span>2:10</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "language" && (
                  <div className="border rounded-3xl shadow-sm overflow-hidden bg-white">
                    <div className="p-6">
                      <h3 className="text-lg font-semibold text-black">Spanish Practice</h3>
                      <p className="text-sm text-gray-500 mb-4">Improve pronunciation while reading along</p>
                      <div className="bg-gray-50 border rounded-2xl p-4 mb-4">
                        <p className="text-sm text-gray-600">
                          El aprendizaje de un nuevo idioma requiere práctica constante. Escuchar y leer al mismo tiempo
                          puede mejorar significativamente su comprensión y pronunciación. Con nuestra aplicación, puede
                          convertir cualquier documento PDF en audio para practicar mientras viaja...
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-4 mt-6">
                        <button
                          className="h-12 w-12 rounded-full bg-black flex items-center justify-center hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 transition-all"
                          onClick={togglePlay}
                        >
                          {isPlaying ? (
                            <Pause className="h-6 w-6 text-white" />
                          ) : (
                            <Play className="h-6 w-6 text-white" />
                          )}
                        </button>
                        <div className="w-full max-w-md">
                          <div className="h-2 bg-gray-200 rounded-full">
                            <div className="h-full w-2/3 bg-black rounded-full"></div>
                          </div>
                          <div className="flex justify-between mt-1 text-xs text-gray-500">
                            <span>2:10</span>
                            <span>3:15</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-11/12 py-12 md:py-24 lg:py-32 bg-black text-white mb-10 rounded-3xl mx-auto pattern">
        <div className="container px-4 md:px-6 mx-auto max-w-7xl">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Ready to Start Listening?</h2>
              <p className="mx-auto max-w-[700px] md:text-xl">
                Transform your PDFs into audio today and experience a new way to consume content.
              </p>
            </div>
            <div className="space-x-4">
              <button className="inline-flex items-center justify-center rounded-full bg-white px-6 py-3 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black transition-all">
                Get Started
              </button>
              <button className="inline-flex items-center justify-center rounded-full border border-white bg-transparent px-6 py-3 text-sm font-medium text-white hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black transition-all">
                View Pricing
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UseCasesPage;