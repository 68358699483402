import { FaExpandAlt } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { Note } from "../../../types";
import { formatSecondsToMMSS } from "../../../utils/time";
import './track-note-pane.styles.scss';

type TrackNotePaneComponentProps = {
  notes: Note[];
  jumpToSecond: (e: number) => void;
  handleRemoveNote: (id: string) => void;
  expandHandler: (n: Note) => void;
}

export const TrackNotePaneComponent = ({ notes, jumpToSecond, handleRemoveNote, expandHandler }: TrackNotePaneComponentProps) => {
  return (
    notes.length === 0 ? (
      <p className="text-gray-500 mt-3">No notes yet.</p>
    ) : <>{
      notes
        .sort((a, b) => a.markPostion - b.markPostion)
        .map((note, idx) => (
          <div
            key={idx}
            className="mt-3 p-3 border bg-white border-gray-300 rounded-lg shadow-sm cursor-pointer"
            onClick={() => jumpToSecond(note.timeStamp)}
          >
            <div className="text-base w-11/12 overflow-hidden whitespace-nowrap truncate text-ellipsis">{note.noteText}</div>
            <div className="text-xs text-gray-700 mb-1">
              At: {formatSecondsToMMSS(note.timeStamp)}
            </div>
            <div className="flex gap-2 align-baseline justify-start items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  expandHandler(note);
                  // setNoteToShow(note);
                }}
                className="bg-white border border-gray-700 hover:bg-gray-100 active:bg-gray-200 text-white px-2 py-1 rounded h-fit w-fit has-tooltip">
                <span className="custom-tooltip">Expand</span>
                <FaExpandAlt className="text-gray-700" />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveNote(note.id);
                }}
                className="delete-button flex items-center space-x-1 bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded">
                <FiTrash2 />
              </button>
            </div>
          </div>
        ))
    }</>
  );
}