import { get, getDatabase, ref, remove, set, update } from "firebase/database";
import { useRecoilValue } from "recoil";
import { authState } from "../state/auth.state";
import { DatabaseUserNote, Note } from "../types";

const useDatabaseNote = () => {
  const userState = useRecoilValue(authState);
  const db = getDatabase();

  const getUserUid = () => {
    if (!userState || !userState.uid) {
      throw new Error("Cannot access notes because no user is currently logged in or 'uid' is missing.");
    }
    return userState.uid;
  };


  const createRecord = async (params: {
    trackId: string;
    note: DatabaseUserNote;
  }) => {
    const { trackId, note } = params;
    const userId = getUserUid();

    if (!trackId) {
      throw new Error("Can't create note without a track identifier.");
    }

    if (!note || !note.id) {
      throw new Error("Can't create an empty note.");
    }

    await set(ref(db, `notes/${userId}/${trackId}/${note.id}`), note);
  };

  const deleteRecord = async (authorId: string, trackId: string, noteId: string) => {
    if (!trackId) {
      throw new Error("Can't delete note without track identifier.");
    }
    if (!noteId) {
      throw new Error("Can't delete note without note identifier.");
    }

    await remove(ref(db, `notes/${authorId}/${trackId}/${noteId}`));
  };

  const updateRecord = async (trackId: string, noteId: string, noteData: Partial<Omit<Note, "id">>) => {
    const userId = getUserUid();

    if (!trackId) {
      throw new Error("Can't update a note without track identifier.");
    }
    if (!noteId) {
      throw new Error("Can't update a note without note identifier.");
    }

    await update(ref(db, `notes/${userId}/${trackId}/${noteId}`), noteData);
  };

  const getAllForUser = async () => {
    const userId = getUserUid();
    const snapshot = await get(ref(db, `notes/${userId}`));
    if (!snapshot.exists()) {
      return {};
    }
    return snapshot.val();
  };

  const getAllForUserAndTrack = async (trackId: string): Promise<Map<string, Note[]> | null> => {
    const userId = getUserUid();

    if (!trackId) {
      throw new Error("Can't get notes without a track identifier.");
    }

    const snapshot = await get(ref(db, `notes/${userId}/${trackId}`));
    if (!snapshot.exists()) {
      return null;
    }
    return snapshot.val();
  };

  return {
    createNote: createRecord,
    deleteNote: deleteRecord,
    updateNote: updateRecord,
    getNotesForUser: getAllForUser,
    getNotesForUserAndTrack: getAllForUserAndTrack,
  };
};

export default useDatabaseNote;
