import React, { useState, useEffect } from "react";
import { sendEmailVerification } from "firebase/auth";
import { MdOutlineEmail } from "react-icons/md";

import { auth } from "../../firebase";
import Button from "../../components/button/button.component";

const ValidateEmailPage = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(0);

  function validateEmail() {
    if (!auth.currentUser) {
      return;
    }

    sendEmailVerification(auth.currentUser, {
      url: `https://readpdf.io/app/validate-email/act`,
    });

    setIsDisabled(true);
    setTimer(40);
  }

  useEffect(() => {
    let interval: any = null;
    if (isDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsDisabled(false);
            return 0;
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isDisabled, timer]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full py-10 text-center mx-auto">
      <MdOutlineEmail size={50} className="mx-auto" />
      <h2 className="text-2xl font-bold text-gray-800 mt-4">
        Verify your email
      </h2>
      <p className="text-gray-600 mt-2 text-lg">
        To start using ReadPDF.io, we need to verify your email.
      </p>
      <Button
        click={validateEmail}
        disabled={isDisabled}
        type="contrast"
        className={`mt-7 text-md ${
          isDisabled
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-black hover:bg-gray-800"
        }`}
      >
        Send Verification Email
      </Button>
      {isDisabled && (
        <p className="text-gray-600 mt-5">
          We just sent you a confirmation email.
          <br />
          Didn't receive it? Try again in {timer}s.
        </p>
      )}
    </div>
  );
};

export default ValidateEmailPage;
