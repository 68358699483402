import { Outlet } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../../state/auth.state";
import {
  navigateToAuthModalProp,
  showAuthModal,
} from "../../state/showAuthModal.state";
import AuthModal from "../auth-modal/auth-modal.component";
import CookiesModal from "../cookies-modal/cookies-modal.component";
import FooterComponent from "../footer/footer.component";
import UnauthenticatedNavbar from "../unauthenticated-navbar/unauthenticated-navbar.component";
import "./layout.styles.scss";

const Layout = () => {
  const [showModal, setShowModal] = useRecoilState(showAuthModal);
  const navigateToGlobalProp = useRecoilValue(navigateToAuthModalProp);
  useRecoilState(authState);

  const toggleAuthModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <UnauthenticatedNavbar />

      <main className="mx-auto pt-0">
        <Outlet />
      </main>
      <FooterComponent />
      <AuthModal
        show={showModal}
        onClose={toggleAuthModal}
        navigateTo={navigateToGlobalProp}
      />
      <CookiesModal />
    </>
  );
};

export default Layout;
