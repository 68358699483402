import * as Amplitude from "amplitudejs";
import { ArrowRight } from "lucide-react";
import { FaCheck } from "react-icons/fa";
import Button from "../../components/button/button.component";
import handleCreatePriceSession from "../../components/pricing-section/handle-price-session";
import WaveformPlayerComponent from "../../components/waveform-player/waveform-player.component";
import { useTitle } from "../../hooks/useTitle.hook";
import "./redeem-coupon.styles.scss";
import useFeatureFlags from "../../hooks/feature-flag.hook";
import { Link } from "react-router-dom";

const RedeemCouponPage = () => {
  useTitle("Listen to your documents, books, and papers", true);
  configureAmplifyPlayer();
  const featureFlags = useFeatureFlags();

  function configureAmplifyPlayer() {
    Amplitude.init({
      songs: [
        {
          name: "AppSumo Welcome",
          artist: "ReadPDF.io",
          url: "/appsumo-teaser.mp3",
        },
      ],
      waveforms: {
        sample_rate: "100",
      },
    });
  }

  return (
    <div className="pattern relative h-screen flex justify-center items-center bg-slate-200">
      <div className="container mx-auto px-4 py-12 mt-20">
        <div className="max-w-3xl mx-auto bg-slate-800/85 rounded-lg shadow-2xl shadow-gray-800/40 p-8">
          <h1 className="text-4xl font-bold mb-6 text-center text-slate-100">
            Hey, you're about to make something great happen!
          </h1>
          <div className="mb-8 w-72 mx-auto hover:shadow-xl hover:shadow-slate-600/20">
            <WaveformPlayerComponent theme="dark" />
          </div>
          <h2 className="text-3xl font-light mb-1 text-center text-slate-100">Lifetime Productivity Boost</h2>
          <p className="text-xl mb-8 text-center text-slate-300 font-semibold">
            Get now for <s className="text-[1rem] text-slate-400">$89,99</s> $53,99
            <span className="inline-flex relative -top-[2px] px-4 py-1 ml-2 rounded-full text-xs font-semibold tracking-wide uppercase bg-amber-400 text-yellow-900">-40% off</span>
          </p>
          <div className="w-full md:w-2/3 mx-auto mb-8">
            <ul className="space-y-4 mb-8">
              {[
                "Life-long PDF into Audio Conversions",
                "Available on All Your Devices",
                "Realistic Voices",
                "Real-time Text-Tracking",
                "Multiple Languages",
                featureFlags.images_processing ? "Image recognition and reading" : "",
                "Notes",
              ].filter(x => x).map((benefit, index) => (
                <li key={index} className="flex items-center text-slate-300">
                  <FaCheck className="w-6 h-6 mr-2 text-green-400" />
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-center space-x-4">
            <Button
              className="!bg-green-500 !hover:bg-green-600 !text-white ripple font-bold text-lg"
              click={() =>
                handleCreatePriceSession({
                  price: "price_1QRfK3FJ3gpTYLFuc4pNDqTI",
                  mode: "one",
                  discount: "58SALE",
                  trackEvent: () => {
                    // @ts-ignore
                    window.fbq("track", "Subscribe", {
                      subscription_id: "price_1QRfK3FJ3gpTYLFuc4pNDqTI",
                    });
                  },
                })
              }
            >
              <span className="inline float-left mr-4">Redeem Now for <s className="font-thin text-sm px-1">$89,99</s> $53,99{" "}</span>
              <ArrowRight size={24} className="-mt-1" />
            </Button>
          </div>
          <hr className="mt-10 border-gray-400 w-2/3 mx-auto" />
          <p className="text-sm text-center mt-5 text-slate-300">
            <Link
              to="/terms-and-conditions"
              className="hover:underline"
              target="_blank"
            >
              ReadPDF.io's terms and conditions
            </Link>{" "}
            apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RedeemCouponPage;
