import { ArrowRight, BookOpen, Calendar, Clock } from "lucide-react";
import { Link } from "react-router-dom";
import Badge from "../../components/badge/badge.component";
import Button from "../../components/button/button.component";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { blogPosts } from "../../services/blog.service";

const BlogPage = () => {
  useTitle("Blog");
  useResetScroll();


  const featuredPost = blogPosts.find((post) => post.featured);

  return (
    <div className="flex min-h-screen flex-col">
      <main className="flex-1 mt-28">
        {featuredPost && (
          <section className="w-full py-12 md:py-24 lg:py-11 bg-muted/50">
            <Link to={featuredPost.slug}>
              <div className="container shadow-sm border-gray-100 hover:border-gray-300 px-4 md:px-6 mx-auto border p-4 rounded-lg hover:scale-[.98] transition-all duration-300 cursor-pointer">
                <div className="grid gap-6 lg:grid-cols-2 lg:gap-12 items-center">
                  <div className="space-y-4">
                    <div className="inline-block rounded-lg bg-primary px-3 py-1 text-sm text-primary-foreground">
                      Featured Post
                    </div>
                    <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                      {featuredPost.title}
                    </h1>
                    <p className="text-muted-foreground md:text-xl">
                      {featuredPost.preview}
                    </p>
                    <div className="flex items-center gap-4 text-sm text-muted-foreground">
                      <div className="flex items-center gap-1">
                        <Calendar className="h-4 w-4" />
                        <span>{featuredPost.published.toDateString()}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Clock className="h-4 w-4" />
                        <span>{featuredPost.readTime} min</span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 min-[400px]:flex-row">
                      <Link to={featuredPost.slug} className="flex items-center gap-1 text-primary">
                        <Button type="contrast">
                          Read Article
                          <ArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="relative aspect-video overflow-hidden rounded-xl">
                    {featuredPost.headImg && (
                      <img src={featuredPost.headImg} alt={featuredPost.title} className="object-cover w-full h-full" />
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </section>
        )}

        {/* Recent Posts Section */}
        <section className="w-full py-12 md:py-16">
          <div className="container px-4 md:px-6 mx-auto">
            {blogPosts.length === 0 ? (
              <div
                className="w-full rounded-lg p-10 text-center shadow-sm relative overflow-hidden animate-gradient"
                style={{
                  backgroundSize: "400% 400%",
                  backgroundImage: "linear-gradient(-45deg, #f4f4f4 0%, #e1e1e1 25%, #e1e1e1 50%, #e1e1e1 75%, #f4f4f4 100%)",
                }}
              >
                <div className="flex flex-col items-center justify-center relative z-10">
                  <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white/80 shadow-sm">
                    <BookOpen className="h-10 w-10 text-slate-600" />
                  </div>
                  <h3 className="mt-6 text-xl font-medium text-slate-800">No articles yet</h3>
                  <p className="mt-2 max-w-md mx-auto text-slate-600">
                    We're working on some amazing content for you. Check back soon!
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                  <div>
                    <h2 className="text-2xl font-bold tracking-tight">Recent Articles</h2>
                    <p className="text-muted-foreground">The latest insights from our blog</p>
                  </div>
                  {/* <Link to="/blog" className="flex items-center gap-1 text-sm font-medium text-primary">
                View all articles
                <ChevronRight className="h-4 w-4" />
              </Link> */}
                </div>
                <div className="mt-8 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {blogPosts.sort((a, b) => b.published.getTime() - a.published.getTime()).map((post, idx) => (
                    <Link key={idx} to={post.slug}>
                      <div className="cursor-pointer p-2 shadow-sm hover:scale-[.98] border border-gray-100 hover:border-gray-200 rounded-lg transition-all duration-300">
                        <div className="relative aspect-video overflow-hidden">
                          <img src={post.headImg} alt={post.title} className="object-cover w-full h-full" />
                        </div>
                        <div className="p-4">
                          <div className="space-y-2">
                            {post.tags.map((tag, idx) => (
                              <Badge bgColor="bg-slate-400" key={idx}>{tag}</Badge>
                            ))}
                            <div className="line-clamp-2 text-xl">
                              {post.title}
                            </div>
                          </div>
                        </div>
                        <div className="p-4 pt-0">
                          <div className="line-clamp-3">
                            {post.preview}
                          </div>
                        </div>
                        <div className="p-4 pt-0">
                          <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-1 text-sm text-muted-foreground">
                              <Calendar className="h-3.5 w-3.5" />
                              <span>{post.published.toDateString()}</span>
                            </div>
                            <div className="flex items-center gap-1 text-sm text-muted-foreground">
                              <Clock className="h-3.5 w-3.5" />
                              <span>{post.readTime} min read</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </>
            )}
          </div>
        </section>
      </main>
    </div >
  )
};

export default BlogPage;
