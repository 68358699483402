import { Calendar, Clock, User } from "lucide-react";
import { useEffect } from "react";
import Markdown from 'react-markdown';
import { Link } from "react-router-dom";
import JumbotronSignUpComponent from "../../components/jumbotron-sign-up/jumpotron-sign-up.component";
import { useResetScroll } from "../../hooks/reset-scroll.hook";

export type BlogPostPageProps = {
  post: PostProps;
}

export type PostProps = {
  published: Date;
  headImg: string;
  imgAlt?: string;
  title: string;
  preview: string;
  readTime: number;
  tags: string[];
  authorName: string;
  markdownPost: string;
  slug: string;
  featured?: boolean;
  description: string;
}


const BlogPostPage = ({ post }: BlogPostPageProps) => {
  useResetScroll();

  useEffect(() => {
    if (!post) return;

    document.title = `${post.title} - ReadPDF.io Blog | Listen to your PDFs`;
    const addedTags: HTMLMetaElement[] = [];

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = post.description;
    addedTags.push(metaDescription);

    if (post.tags.length > 0) {
      const metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      metaKeywords.content = post.tags.join(', ');
      addedTags.push(metaKeywords);
    }

    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'index, follow';
    addedTags.push(metaRobots);

    // Open Graph Title
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
    ogTitle.content = post.title;
    addedTags.push(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.content = post.description;
    addedTags.push(ogDescription);

    if (post.slug) {
      const ogUrl = document.createElement('meta');
      ogUrl.setAttribute('property', 'og:url');
      ogUrl.content = `https://readpdf.io/blog/${post.slug}`;
      addedTags.push(ogUrl);
    }

    if (post.headImg) {
      const ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      ogImage.content = post.headImg;
      addedTags.push(ogImage);
    }

    // const linkCanonical = document.createElement('link');
    // linkCanonical.rel = 'canonical';
    // linkCanonical.href = `https://example.com/post/${post.slug}`;
    // document.head.appendChild(linkCanonical);

    addedTags.forEach(tag => document.head.appendChild(tag));

    return () => {
      addedTags.forEach(tag => {
        document.head.removeChild(tag);
      });
    };
  }, [post]);

  return (
    <main className="container mx-auto px-4 py-12 mt-20">
      <article className="max-w-4xl mx-auto relative">
        <div className="flex items-center text-sm text-muted-foreground mb-8">
          <Link to="/" className="hover:text-foreground hover:underline">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/blog" className="hover:text-foreground hover:underline">
            Blog
          </Link>
          <span className="mx-2">/</span>
          <span className="cursor-pointer hover:underline">{post.title}</span>
        </div>

        {/* Post Header */}
        <div className="mb-8">
          <div className="flex flex-wrap gap-2 mb-4">
            {post.tags.map((tag, idx) => (
              <span key={idx} className="inline-flex bg-white items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-primary/10 text-primary">
                {tag ?? ''}
              </span>
            ))}
          </div>
          <h1 className="text-4xl md:text-5xl font-bold tracking-tight mb-6">
            {post.title}
          </h1>

          <div className="flex flex-wrap items-center gap-6 text-sm text-muted-foreground mb-6">
            <div className="flex items-center gap-2 has-tooltip">
              <span className="custom-tooltip -mt-14 ml-0">Published on</span>
              <Calendar className="h-4 w-4" />
              <span>{post.published?.toDateString()}</span>
            </div>
            <div className="flex items-center gap-2 has-tooltip">
              <span className="custom-tooltip -mt-14 ml-0">Time to Read</span>
              <Clock className="h-4 w-4" />
              <span>{post.readTime} min read</span>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <div className="relative h-10 w-10 rounded-full overflow-hidden">
              <User className="absolute inset-0 h-full w-full text-muted-foreground" />
            </div>
            <div>
              <p className="font-medium">{post.authorName}</p>
              <p className="text-sm text-muted-foreground">Author</p>
            </div>
          </div>
        </div>

        {/* Featured Image */}
        <div className="relative aspect-video rounded-lg overflow-hidden mb-10 w-full h-[320px] bg-slate-200">
          <img
            src={post.headImg}
            alt={post.title}
            className="w-full"
          />
          <p className="text-gray-500 text-sm absolute bottom-2 left-2 bg-white/80 px-2 py-1 rounded-md">
            {post.imgAlt ?? ''}
          </p>
        </div>

        {/* Post Content */}
        <article className="prose prose-lg dark:prose-invert max-w-none mb-12">
          <Markdown>{post.markdownPost}</Markdown>
        </article>

        <JumbotronSignUpComponent />
      </article>
    </main>
  )
};

export default BlogPostPage;

