import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <footer className="bg-gray-800 text-white py-12 rounded-t-2xl">
      <div className="container mx-auto px-6">
        <div className="footer-links grid md:grid-cols-4 gap-10">
          <div className="col-span-4 md:col-span-1 mx-auto mt-0 md:mt-6 mb-6 md:mb-3">
            <Link to="/" className="text-2xl font-bold text-white">
              <img className="h-9" src="/logo-rpf-retina.svg" alt="ReadPDF.io Logo" />
            </Link>
            <p className="text-gray-300 text-[0.9rem] mt-4 mb-4">
              ReadPDF.io is the way to boost your productivity by listening to your academic papers, documents and books instead of reading them.
            </p>
          </div>
          <div className="col-span-2 md:col-span-1 mt-0 md:mt-5 mb-6 md:mb-5 md:mx-auto">
            <h3 className="text-lg font-bold mb-4">About</h3>
            <ul>
              <li>
                <Link
                  to={"/blog"} className="footer-link-item">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/use-cases" className="footer-link-item">
                  Use Cases
                </Link>
              </li>
              <li>
                <Link to="/careers" className="footer-link-item">
                  Careers
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-2 md:col-span-1 mt-0 md:mt-5 mb-6 md:mb-5 md:mx-auto">
            <h3 className="text-lg font-bold mb-4">Links</h3>
            <ul>
              <li>
                <Link to="/" className="footer-link-item">
                  Home
                </Link>
              </li>
              <li>
                <Link to={"/privacy-policy"} className="footer-link-item">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to={"/terms-and-conditions"}
                  className="footer-link-item">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-4 md:col-span-1 mt-0 md:mt-6 flex space-x-6 justify-center">
            <Link
              to="https://www.facebook.com/profile.php?id=61563878062519"
              target="_blank"
              className="footer-link-item"
            >
              <FaFacebook size={24} />
            </Link>
            <Link
              to="https://www.instagram.com/listentopdfs/"
              target="_blank"
              className="footer-link-item"
            >
              <FaInstagram size={24} />
            </Link>
            <Link
              to="https://www.linkedin.com/company/readpdfio/"
              target="_blank"
              className="footer-link-item"
            >
              <FaLinkedin size={24} />
            </Link>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-400">
          <p>
            &copy; {new Date().getFullYear()} ReadPDF.io. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;