

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

const qaData: FAQItem[] = [
  {
    id: 1,
    question: "Can I convert any PDF to audio?",
    answer:
      "Indeed, you can convert any PDF into audio with ReadPDF.io. As long as it belongs to you and you have the permissions to manage it, you are free to upload and listen to it.",
  },
  {
    id: 7,
    question: "Can I try ReadPDF.io for free?",
    answer:
      "Yes, you can try it out for free for 7 days and won't be charged during this period. You can cancel anytime.",
  },
  {
    id: 2,
    question: "What formats are supported?",
    answer:
      "ReadPDF.io currently only supports listening to PDFs.",
  },
  {
    id: 3,
    question: "Is my data secure?",
    answer:
      "Yes, your data is securely stored, employing the latest security practices while uploading and during storing.",
  },
  {
    id: 4,
    question: "Can I use ReadPDF.io for free?",
    answer:
      "You can indeed use ReadPDF.io for free. However, the free version is only offering limited listening, no sharing, nor image description.",
  },
  {
    id: 5,
    question: "A friend shared a ReadPDF.io link with me. Can I listen it for free?",
    answer:
      "You can listen the document your friend shared with you for free. If you want to upload your document, you might need to consider one of the tiers we offer.",
  }
];

export default function FAQSection() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="columns-1 sm:columns-2 lg:columns-3 gap-7 space-y-7">
        {qaData.map((item) => (
          <div
            key={item.id}
            className="break-inside-avoid mb-4 border border-black rounded-lg p-4 bg-white hover:shadow-md transition-shadow duration-200"
          >
            <h3 className="text-lg font-semibold mb-2">{item.question}</h3>
            <div className="mt-2 text-gray-700">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
