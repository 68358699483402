export function formatSecondsToMMSS(timeInSeconds: number) {
  // Remove the decimal part so we only deal with whole seconds
  const totalSeconds = Math.floor(timeInSeconds);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const mm = String(minutes).padStart(2, "0");
  const ss = String(seconds).padStart(2, "0");

  return `${mm}:${ss}`;
}