export const ActiveSubscriptionTypes = ["active", "trialing"];

export type UserTracks = {
  recordingUrl: string;
  trackUid: string;
  date: string;
  fileId: string;
  model: string;
  timestamps: any[]; // todo - strictly type
  coverPage: string;
  demoFile: string;
};

export type DatabaseUserData = {
  email: string;
  name: string;
  stripeSessionId: string;
  photo: string | null;
  defaultModel: ConversionModels;
  defaultLanguage?: string;
};

export type StripeSessionStatus = {
  price: string;
  status: string;
  customer_email: string;
};

export type FileStatusInfo = {
  fileId: string;
  status: string;
  fileName: string;
  location: string;
};

export type AvailableCurrencies = "EUR" | "USD";

export enum ConversionModels {
  Default = "default",
  Visuals = "visuals",
}

export type AvailableLanguage = {
  name: string;
  country: string;
  code: string;
  flag: string;
  suggested: boolean;
};

export type Note = {
  id: string;
  noteText: string;
  markPostion: number;
  timeStamp: number;
};

export type DatabaseUserNote = Note & { trackAuthorId: string };