import { CiCircleInfo } from "react-icons/ci";
import { Link } from "react-router-dom";

export const TrackPageSubscribePromptComponent = () => {
  return <div className="text-center py-4 lg:px-4">
    <div
      className="p-2 bg-blue-200 items-center text-gray-600 leading-none rounded-full flex lg:inline-flex shadow-sm px-5"
      role="alert"
    >
      <div className="relative -top-0.5 mr-3">
        <CiCircleInfo className="w-4 h-4 mt-1 ml-2" />
      </div>
      <span className="mr-2 text-left flex-auto">
        You can listen to your PDFs and share them with others now!{" "}
        <Link className="underline" to="/app/pricing">
          Try now!
        </Link>
      </span>
    </div>
  </div>;
};