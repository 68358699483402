import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { featureFlagsState } from "../state/feature-flags.state";

const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useRecoilState(featureFlagsState);

  const fetchFeatureFlags = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_APPLICATION_URL}/features`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const normalizedData = Object.keys(data).reduce((acc: any, key: any) => {
        acc[key] = data[key] === "True";
        return acc;
      }
        , {});
      setFeatureFlags(normalizedData);
    } catch (error) { }
  };

  useEffect(() => {
    if (Object.keys(featureFlags).length === 0) {
      fetchFeatureFlags().then();
    }
  }, []);

  return featureFlags;
};

export default useFeatureFlags;