import { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { showAuthModal } from "../../state/showAuthModal.state";
import { showFileConvertedModal } from "../../state/showFileConverted.state";
import { verifiedEmailState } from "../../state/verified-email.state";
import { ActiveSubscriptionTypes } from "../../types";
import AuthModal from "../auth-modal/auth-modal.component";
import UploadFinishedModal from "../upload-finished-modal/upload-finished-modal.component";
import UploadProgress from "../upload-progress/upload-progress.component";

import { ChevronLeft, ChevronRight, Files, Menu, NotebookPen, Star, TestTubeDiagonal } from "lucide-react";
import { FaUpload } from "react-icons/fa6";
import useFeatureFlags from "../../hooks/feature-flag.hook";
import FooterComponent from "../footer/footer.component";
import "./authenticated-layout.scss";

const AuthenticatedLayout = () => {
  const { user, databaseUser, loading, logout, subscriptionStatus } = useCombinedAuthHook();
  const [showModal, setShowModal] = useRecoilState(showAuthModal);
  const [showFileConvertedModalState, setShowFileConvertedModalState] = useRecoilState(showFileConvertedModal);
  const emailVerified = useRecoilValue(verifiedEmailState);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidenavCollapsed, setSidenavCollapsed] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(true);
  const [showSubscribePrompt, setShowSubscribePrompt] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useFeatureFlags();

  useEffect(() => {
    if (!hasActiveSubscription) {
      setShowSubscribePrompt(true);
    } else {
      setShowSubscribePrompt(false);
    }
  }, [hasActiveSubscription]);

  useEffect(() => {
    if (loading) return;
    setHasActiveSubscription(
      ActiveSubscriptionTypes.includes(subscriptionStatus)
    );
  }, [subscriptionStatus, loading]);

  // If ever using the AI chatbot again, comment this out
  // useAiChatbot();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !event.target.closest("#dropdown-user") &&
        !event.target.closest('[data-dropdown-toggle="dropdown-user"]')
      ) {
        setDropdownOpen(false);
      }

      if (
        !event.target.closest("#logo-sidebar") &&
        !event.target.closest('[aria-controls="logo-sidebar"]') &&
        window.innerWidth < 640
      ) {
        setSidenavOpen(false);
      }
    };

    const handleScroll = () => {
      setDropdownOpen(false);
      if (window.innerWidth < 640) {
        setSidenavOpen(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setSidenavOpen(true);
      } else {
        setSidenavOpen(false);
      }
    };

    const checkSidenavCollapsed = () => {
      const storedSidenavCollapsed = localStorage.getItem("sidenavCollapsed");
      if (storedSidenavCollapsed) {
        setSidenavCollapsed(JSON.parse(storedSidenavCollapsed));
      }
    };

    checkSidenavCollapsed();
    handleResize();
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setDropdownOpen(false);
    if (window.innerWidth < 640) {
      setSidenavOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (emailVerified) return;

    navigate("/app/validate-email");
  }, [navigate, emailVerified]);

  const toggleAuthModal = () => {
    setShowModal(!showModal);
  }

  const toggleSidenavCollapse = () => {
    setSidenavCollapsed(!sidenavCollapsed);
    localStorage.setItem("sidenavCollapsed", JSON.stringify(!sidenavCollapsed));
  }

  const getInitials = (name?: string) => {
    return name?.charAt(0) ?? "R";
  }

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={() => setSidenavOpen(!sidenavOpen)}
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 transition-all"
              >
                <span className="sr-only">Toggle sidebar</span>
                {/* {sidenavOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />} */}
                <Menu className="w-6 h-6" />
              </button>
              <Link to="#" className="flex ms-2 md:me-24">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  <img src="/logo-rpf.svg" alt="Logo" className="h-9" />
                </span>
              </Link>
            </div>
            <div className="flex items-center ">
              <Link
                to={"/app/upload"}
                className="mr-2 rounded-full py-[6px] px-4 text-sm cursor-pointer -inset-[0.07rem] bg-gradient-to-r from-purple-200/50 via-purple-200 to-purple-200/50 
               group-hover:opacity-100 transition duration-500 group-hover:duration-200 animate-gradient text-gray-600 border border-gray-300/25 hover:text-gray-700 hover:border-gray-500">
                <FaUpload size={12} className="inline mr-1 md:mr-2" />
                <span className="hidden md:inline">Upload a PDF</span>
              </Link>
              <div className="relative flex justify-center">
                <button
                  type="button"
                  className="self-center text-sm rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 transition-transform hover:scale-105"
                  aria-expanded="false"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  data-dropdown-toggle="dropdown-user"
                >
                  {user?.photo !== null ? (
                    <img
                      className="w-9 h-9 rounded-full border-2 border-white"
                      referrerPolicy="no-referrer"
                      src={user.photo}
                      alt="User"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-sky-500 to-indigo-600 text-white text-center flex justify-center items-center align-middle">
                      <span>{getInitials(user?.name ?? "R")}</span>
                    </div>
                  )}
                </button>
                <div
                  className={`absolute right-0 top-full z-50 mt-2 w-48 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow-lg dark:bg-gray-700 dark:divide-gray-600 transition-all duration-200 origin-top-right ${!dropdownOpen ? "opacity-0 scale-95 invisible" : "opacity-100 scale-100 visible"
                    }`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm text-gray-900 dark:text-white font-medium" role="none">
                      {databaseUser?.name ?? ""}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-300" role="none">
                      {databaseUser?.email ?? ""}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    {ActiveSubscriptionTypes.includes(subscriptionStatus) && databaseUser.stripeSessionId && (
                      <li>
                        <form
                          className="block"
                          method="POST"
                          action={`${process.env.REACT_APP_APPLICATION_URL}/create-portal-session`}
                        >
                          <input type="hidden" name="lookup_key" value={databaseUser.stripeSessionId} />
                          <button
                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white transition-colors"
                            type="submit"
                          >
                            Subscription
                          </button>
                        </form>
                      </li>
                    )}
                    <li>
                      <Link
                        to={"/app/settings"}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white transition-colors"
                        role="menuitem"
                      >
                        Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white transition-colors"
                        role="menuitem"
                        onClick={() => logout()}
                      >
                        Sign out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-30 h-screen pt-20 transition-all duration-300 bg-white border-r border-gray-200 shadow-sm
          ${sidenavOpen ? "translate-x-0" : "-translate-x-full"} 
          ${sidenavCollapsed ? "sm:w-20" : "sm:w-64"} 
          sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <button
          onClick={toggleSidenavCollapse}
          className="absolute right-0 top-20 -mr-3 hidden sm:flex items-center justify-center w-6 h-6 bg-white rounded-full border border-gray-200 shadow-sm cursor-pointer text-gray-500 hover:text-gray-700 transition-colors"
        >
          {sidenavCollapsed ? <ChevronRight size={14} /> : <ChevronLeft size={14} />}
        </button>

        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium mt-2">
            <li>
              <NavLink
                to="/app"
                end
                className={({ isActive }) => `flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group transition-colors ${sidenavCollapsed ? "md:justify-center" : ""} ${isActive ? 'bg-gray-100' : ''}`}
              >
                <Files
                  size={20}
                  className="text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                />
                <span className={`ms-3 whitespace-nowrap ${sidenavCollapsed ? "sm:hidden" : ""}`}>My Files</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/app/upload"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group transition-colors ${sidenavCollapsed ? "md:justify-center" : ""} ${isActive ? 'bg-gray-100' : ''}`}
              >
                <FaUpload className="text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className={`ms-3 whitespace-nowrap ${sidenavCollapsed ? "sm:hidden" : ""}`}>Upload PDF</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/app/notes"
                className={({ isActive }) => `flex items-center p-2 rounded-lg text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group transition-colors ${sidenavCollapsed ? "md:justify-center" : ""} ${isActive ? 'bg-gray-100' : ''}`}
              >
                <NotebookPen
                  size={20}
                  className="text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                />
                <span className={`ms-3 whitespace-nowrap ${sidenavCollapsed ? "sm:hidden" : ""}`}>Notes</span>
              </NavLink>
            </li>
            <hr className="my-4" />
            {showSubscribePrompt && (
              <li className="relative group cursor-pointer">
                <div
                  className="absolute -inset-[0.07rem] bg-gradient-to-r from-purple-500 via-indigo-500 to-purple-500 rounded-lg 
                  group-hover:opacity-100 transition duration-500 group-hover:duration-200 animate-gradient"
                ></div>
                <Link
                  to={"/app/pricing"}
                  className={`relative flex items-center py-3 ring-1 ring-gray-900/5 rounded-lg leading-none justify-start p-2 
                  bg-white/80 text-gray-900 dark:text-white hover:bg-white/70 dark:hover:bg-gray-700 group transition-colors  ${sidenavCollapsed ? "md:justify-center" : ""}`}
                  role="menuitem"
                >
                  <Star
                    size={20}
                    strokeWidth={2}
                    className="text-purple-500 transition duration-75 
                    dark:text-purple-400 group-hover:text-purple-600 dark:group-hover:text-purple-300"
                  />
                  <span className={`ms-3 whitespace-nowrap ${sidenavCollapsed ? "sm:hidden" : ""}`}>Go Premium</span>
                </Link>
              </li>
            )}
            <li>
              <Link
                to="https://readpdfio.canny.io/feature-requests"
                target="_blank"
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group transition-colors ${sidenavCollapsed ? "md:justify-center" : ""}`}
              >
                <TestTubeDiagonal size={20} className="text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className={`ms-3 whitespace-nowrap ${sidenavCollapsed ? "sm:hidden" : ""}`}>
                  Request a Feature
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      <div className={`p-4 transition-all duration-300 ${sidenavCollapsed ? "sm:ml-20" : "sm:ml-64"}`}>
        <div className="p-2 border-gray-200 rounded-lg dark:border-gray-700 mt-14 mb-6 min-h-screen relative">
          <Outlet />
        </div>

        <FooterComponent />
      </div>

      {!!databaseUser && <UploadProgress />}
      <AuthModal show={showModal} onClose={toggleAuthModal} />
      <UploadFinishedModal
        show={showFileConvertedModalState}
        onClose={() => {
          setShowFileConvertedModalState(false);
        }}
      />
    </>
  );
};

export default AuthenticatedLayout;

