type PlaybackSpeedComponentProps = {
  toggle: boolean;
  setPlaybackSpeedTo: (x: number) => void;
};

const PlaybackSpeedComponent = ({ toggle, setPlaybackSpeedTo }: PlaybackSpeedComponentProps) => {
  return (
    <div
      className={`playback-toggler absolute left-0 -top-[350px] z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow-xl dark:bg-gray-700 dark:divide-gray-600 ${toggle ? "" : "hidden"}`}
      id="dropdown-user"
    >
      <div className="px-4 py-3" role="none">
        <p className="text-md text-gray-900 dark:text-white">
          Playback Speed
        </p>
      </div>
      <ul className="py-1" role="none">
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(0.5)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            0.5
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(0.75)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            0.75
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(1)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            1
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(1.15)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            1.15
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(1.25)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            1.25
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(1.5)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            1.5
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(1.75)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            1.75
          </span>
        </li>
        <li>
          <span
            onClick={() => setPlaybackSpeedTo(2)}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            2
          </span>
        </li>
      </ul>
    </div>
  );
};

export default PlaybackSpeedComponent;
