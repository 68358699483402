import { User } from "@firebase/auth";
import { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { auth } from "../firebase";
import { authState, AuthUser } from "../state/auth.state";
import { useDatabaseUser } from "./database-user.hook";
import { subscriptionStatusState } from "../state/subscription-status.state";
import { signOut } from "firebase/auth";
import { verifiedEmailState } from "../state/verified-email.state";
import sendEmail from "../utils/send-email";

export const useAuth = () => {
  const [userState, setUserState] = useRecoilState(authState);
  const [isLoading, setIsLoading] = useState(!!userState ? false : true);
  const setEmailVerified = useSetRecoilState(verifiedEmailState);
  const resetSubscription = useResetRecoilState(subscriptionStatusState);

  useDatabaseUser();

  const logout = async () => {
    await signOut(auth);
    setUserState(undefined as never as AuthUser);
  };

  const authToken = (async () => await auth.currentUser?.getIdToken(false))();

  const handleUser = (user: User | null) => {
    if (!user) {
      setUserState(null as never);
      resetSubscription();
      setIsLoading(false);
      return;
    }

    if (userState?.uid && userState.uid === user.uid) {
      return;
    }

    setUserState({
      email: user.email!,
      name: user.displayName!,
      photo: user.photoURL,
      uid: user.uid,
      emailVerified: user.emailVerified,
    });

    const eiv = localStorage.getItem("eiv");

    if (!!eiv && eiv === "invalid" && user.emailVerified) {
      sendEmail(authToken, user.uid).then((data: { status: number }) => {
        if (data.status <= 209) localStorage.removeItem("eiv");
      });
    }

    setEmailVerified(user.emailVerified);
    resetSubscription();
    setIsLoading(false);
  };

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(handleUser);
    return () => unsubscribe();
  }, []);

  return {
    user: userState,
    logout: logout,
    isLoading: isLoading,
    authToken: authToken
  };
};
