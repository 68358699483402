import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/button/button.component";
import handleCreatePriceSession from "../../components/pricing-section/handle-price-session";
import PricingFeaturesListComponent, { PricingFeatureComponent as Feature } from "../../components/pricing-section/pricing.features-list.component";
import useCurrency from "../../hooks/currency.hook";
import useFeatureFlags from "../../hooks/feature-flag.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";

const PricingTablePage = () => {
  useTitle("Pricing");
  useResetScroll();
  const { databaseUser } = useCombinedAuthHook();

  const [annual, setAnnual] = useState(false);
  const featureFlags = useFeatureFlags();
  const currency = useCurrency();

  const activePrice = (): {
    monthly: string;
    yearly: string;
    original: string;
  } => {
    switch (currency) {
      case "USD":
        return {
          monthly: "$8.99",
          yearly: "$53.99",
          original: "$89.99",
        };
      default:
        return {
          monthly: "7.99€",
          yearly: "51.59€",
          original: "85.99€",
        };
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-black sm:text-5xl sm:tracking-tight lg:text-6xl">
            Simple, transparent pricing
          </h1>
          <p className="mt-5 max-w-xl mx-auto text-xl text-slate-600">Choose the plan that works best for you.</p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-200 transition-all hover:shadow-xl h-fit">
            <div className="p-8">
              <h2 className="text-2xl font-bold text-black">Subscription Plan</h2>
              <p className="text-slate-600 mt-2">Flexible payment options</p>

              <div className="mt-6">
                <div className="flex items-end">
                  <span className="text-5xl font-extrabold text-black">{activePrice().monthly}</span>
                  <span className="text-slate-600 ml-2 mb-1">{annual ? "/year" : "/month"}</span>
                </div>
              </div>

              <PricingFeaturesListComponent>
                <Feature>Try for free</Feature>
                <Feature>Unlimited PDF uploads</Feature>
                <Feature>Available on All Your Devices</Feature>
                <Feature>High-quality MP3 conversion</Feature>
                <Feature>Share converted files</Feature>
                <Feature>
                  <>
                    Text <span className="bg-yellow-100 text-black">highlighting</span>
                  </>
                </Feature>
                {featureFlags.images_processing && <Feature>AI Description of images and charts</Feature>}
                <Feature>Take Notes</Feature>
                <Feature>Cancel anytime</Feature>
              </PricingFeaturesListComponent>
            </div>

            <div className="p-8 bg-gray-50 border-t border-gray-200">
              <Button type="contrast" className="w-full text-lg" click={() =>
                handleCreatePriceSession({
                  price: "price_1QRfO5FJ3gpTYLFurNywk6Fw",
                  mode: "sub",
                  trackEvent: () => {
                    // @ts-ignore
                    window.fbq("track", "Subscribe", {
                      subscription_id: "price_1QRfO5FJ3gpTYLFurNywk6Fw",
                    });
                  },
                  successUrl: "/app/return",
                  cancelUrl: "/app/flow/cancel",
                  customerEmail: databaseUser.email
                })
              }>
                Get Started
              </Button>
              <p className="text-center text-sm text-slate-600 mt-4">{annual ? "Billed annually" : "Billed monthly"}</p>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-200 transition-all hover:shadow-xl">
            <div className="p-8">
              <h2 className="text-2xl font-bold text-black">Lifetime Access</h2>
              <p className="text-slate-600 mt-2">One-time payment</p>

              <div className="mt-6">
                <div className="flex items-end">
                  <span className="text-5xl font-extrabold text-black">{activePrice().original}</span>
                  <span className="text-slate-600 ml-2 mb-1">one-time</span>
                </div>
                <p className="text-slate-600 mt-2">Pay once, use forever</p>
              </div>

              <PricingFeaturesListComponent>
                <Feature>Try for free</Feature>
                <Feature>Unlimited PDF uploads</Feature>
                <Feature>Available on All Your Devices</Feature>
                <Feature>High-quality MP3 conversion</Feature>
                <Feature>Share converted files</Feature>
                <Feature>
                  <>
                    Text <span className="bg-yellow-100 text-black">highlighting</span>
                  </>
                </Feature>
                {featureFlags.images_processing && <Feature>AI Description of images and charts</Feature>}
                <Feature>Take Notes</Feature>
                <Feature>All Future Updates</Feature>
              </PricingFeaturesListComponent>
            </div>

            <div className="p-8 bg-gray-50 border-t border-gray-200">
              <Button type="secondary" className="w-full text-lg" click={() =>
                handleCreatePriceSession({
                  price: "price_1QRfK3FJ3gpTYLFuc4pNDqTI",
                  mode: "one",
                  trackEvent: () => {
                    // @ts-ignore
                    window.fbq("track", "Subscribe", {
                      subscription_id: "price_1QRfK3FJ3gpTYLFuc4pNDqTI",
                    });
                  },
                  successUrl: "/app/return",
                  cancelUrl: "/app/flow/cancel",
                  customerEmail: databaseUser.email
                })
              }>
                Buy lifetime access
              </Button>
              <p className="text-center text-sm text-slate-600 mt-4">No recurring payments</p>
            </div>
          </div>
        </div>

        <div className="mt-16 text-center">
          <p className="text-slate-600">
            Have questions?{" "}
            <Link to="mailto:support@readpdf.io" className="text-black font-medium underline">
              Contact our support
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
};

export default PricingTablePage;
