import { ArrowLeft, Home, Search, FileQuestion, Compass, Coffee, ArrowRight, ArrowRightCircleIcon } from "lucide-react"
import { useTitle } from "../../hooks/useTitle.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { Link } from "react-router-dom";
import { blogPosts } from "../../services/blog.service";

const NotFoundPage = () => {
  useTitle("Article not found");
  useResetScroll();

  return (
    <main className="container mx-auto px-4 py-16 md:py-24 mt-16">
      <div className="max-w-3xl mx-auto text-center">
        <div className="mb-8 flex justify-center">
          <div className="relative h-32 w-32 rounded-full bg-gray-100 flex items-center justify-center">
            <FileQuestion className="h-16 w-16 text-gray-400" />
          </div>
        </div>

        <h1 className="text-5xl md:text-6xl font-bold tracking-tight mb-4">404</h1>
        <h2 className="text-2xl md:text-3xl font-semibold mb-6">Page Not Found</h2>
        <p className="text-lg text-gray-600 mb-8 max-w-xl mx-auto">
          The page you're looking for doesn't exist or has been moved. Please check the URL or try searching for what
          you need.
        </p>

        <div className="mb-12">
          <h3 className="text-lg font-semibold mb-4">You might want to check out:</h3>
          <div className="grid md:grid-cols-2 gap-4 max-w-2xl mx-auto">
            <Link to={'/'}
              className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 transition-colors"
            >
              <Home className="h-5 w-5 text-black mr-3" />
              <span className="font-medium">Home Page</span>
            </Link>
            <Link
              to="/blog"
              className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 transition-colors"
            >
              <Coffee className="h-5 w-5 text-black mr-3" />
              <span className="font-medium">Blog Articles</span>
            </Link>
            <Link
              to="/contact"
              className="flex items-center p-4 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 transition-colors"
            >
              <Search className="h-5 w-5 text-black mr-3" />
              <span className="font-medium">Site Map</span>
            </Link>
          </div>
        </div>

        <div className="flex justify-center">
          <Link
            to="/"
            className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-black text-white hover:bg-slate-700 h-11 px-6 py-2"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Home
          </Link>
        </div>

        <div className="mt-16">
          <h3 className="text-xl font-semibold mb-6">Popular Articles</h3>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
            {blogPosts.length === 0 && (
              <div className="col-span-3 text-center">
                <p className="text-gray-500">No articles available at the moment.</p>
              </div>)}
            {blogPosts.slice(0, 3).map((post, i) => (
              <div key={i} className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
                <div className="h-40 relative">
                  <img
                    src={post.headImg} alt={post.title}
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="p-4">
                  <h4 className="font-bold text-lg mb-2 line-clamp-1">
                    {post.title}
                  </h4>
                  <p className="text-gray-500 text-sm line-clamp-2 mb-3">
                    {post.preview}
                  </p>
                  <Link to={post.slug}
                    className="text-black text-sm font-medium hover:underline inline-flex items-center"
                  >
                    Read Article
                    <ArrowRightCircleIcon size={18} className="ml-1 -mt-[2px]" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage;