import { useState } from "react";

import "./upload-progress.styles.scss";
import { useFilesStatusCheck } from "../../hooks/useFilesStatusCheck.hook";
import { FaFileUpload } from "react-icons/fa";
import { FileWarning } from "lucide-react";

const UploadProgress = () => {
  const { filesUnderConversion, error } = useFilesStatusCheck();
  const [isOpen, setIsOpen] = useState(true);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navdrawer" hidden={filesUnderConversion.length === 0}>
      <div className="fixed bottom-4 left-4 z-[51]">
        <button
          className={`relative bg-white text-gray-600 p-2 rounded-full shadow-xl ${isOpen ? "translate-x-2" : "translate-x-0"
            } transition-transform duration-300 ease-in-out`}
          onClick={toggleDrawer}
        >
          <FaFileUpload className="inline -mt-1 mr-2" />
          Conversion progress
          {!isOpen && filesUnderConversion.length > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
              {filesUnderConversion.length}
            </span>
          )}
        </button>
      </div>
      <div
        className={`fixed bottom-20 left-0 h-fit w-1/3 bg-white text-black p-6 shadow-lg z-50 transform ${isOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out`}
        style={{ width: "300px" }}
      >
        <div className="flex justify-between items-center mb-4">
          <button
            className="bg-white text-slate-600 underline p-1 rounded"
            onClick={toggleDrawer}
          >
            Close
          </button>
        </div>
        {filesUnderConversion.length > 0 ? (
          <>
            <h4 className="font-semibold text-lg">Files in progress:</h4>
            <ul>
              {filesUnderConversion.map((file) => (
                <li
                  key={file.fileId}
                  className="mb-4 flex items-center justify-between"
                >
                  <div className="w-full">
                    <p className="font-bold truncate w-full text-nowrap">
                      {file.fileName}
                    </p>
                    <div className="flex gap-2 items-center">
                      {error ? (
                        <>
                          <p className="inline">Error during processing</p>
                          <FileWarning color="red" size={20} />
                        </>
                      ) : (
                        <>
                          <p className="text-sm">{file.status}</p>
                          {file.status === "processing" && !error ? (
                            <div className="pulse-loader bg-gray-900 rounded-full h-3 w-3"></div>
                          ) : (
                            <div className="tick">L</div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>No PDFs are under conversion</p>
        )}
      </div>
    </div>
  );
};

export default UploadProgress;
