import { useCallback, useEffect } from "react";
import Amplitude from "amplitudejs";

const useAmplitudePlayer = (config: any) => {

  const startPLayer = useCallback(() => {
    Amplitude.init(config);
  }, [config]);

  useEffect(() => {

    return () => {
      Amplitude.stop();
    };
  }, []);

  return startPLayer;
};

export default useAmplitudePlayer;
