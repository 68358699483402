import * as Amplitude from "amplitudejs";
import { useEffect, useState } from "react";
import { BsImageFill } from "react-icons/bs";
import { FaMicrophoneAlt } from "react-icons/fa";
import { FaLanguage, FaSquarePen } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Badge from "../../components/badge/badge.component";
import PdfPlayerComponent from "../../components/pdf-player/pdf-player.component";
import WaveformPlayerComponent from "../../components/waveform-player/waveform-player.component";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import useCurrency from "../../hooks/currency.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { authState } from "../../state/auth.state";

import Flag from "cute-countries-react";
import Button from "../../components/button/button.component";
import FAQSection from "../../components/faq/faq.component";
import JumbotronSignUpComponent from "../../components/jumbotron-sign-up/jumpotron-sign-up.component";
import handleCreatePriceSession from "../../components/pricing-section/handle-price-session";
import PricingSectionComponent from "../../components/pricing-section/pricing-section.component";
import PricingFeaturesListComponent, {
  PricingFeatureComponent as Feature,
} from "../../components/pricing-section/pricing.features-list.component";
import RatingSection from "../../components/rating/rating.component";
import useFeatureFlags from "../../hooks/feature-flag.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import "./home.styles.scss";

const HomePage = () => {
  useTitle();
  useResetScroll();

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const authUser = useRecoilValue(authState);
  const navigate = useNavigate();
  const { user } = useCombinedAuthHook();
  const currency = useCurrency();

  const [isYearly, setIsYearly] = useState(false);

  configureAmplifyPlayer();

  function configureAmplifyPlayer() {
    Amplitude.init({
      songs: [
        {
          name: "Subtropical Climate",
          artist: "ReadPDF.io",
          url: "/subtropical-climate.mp3",
        },
        {
          name: "Agricultural Analysis",
          artist: "ReadPDF.io",
          url: "/agricultural_analysis.mp3",
        },
        {
          name: "DNA",
          artist: "ReadPDF.io",
          url: "/dna_sample.mp3",
        },
        {
          name: "Flamenco",
          artist: "ReadPDF.io",
          url: "/flamenco.mp3",
        },
      ],
      waveforms: {
        sample_rate: "100",
      },
    });
  }

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  useEffect(() => {
    if (user) {
      navigate("/app");
    }
  }, [user, navigate]);

  const activePrice = (): {
    monthly: string;
    yearly: string;
    original: string;
  } => {
    switch (currency) {
      case "USD":
        return {
          monthly: "$8.99",
          yearly: "$53.99",
          original: "$89.99",
        };
      default:
        return {
          monthly: "7.99€",
          yearly: "51.59€",
          original: "85.99€",
        };
    }
  };

  const getActiveFeatureCols = featureFlags.images_processing ? `grid-cols-4` : `grid-cols-3`;

  return (
    <>
      <section
        className={`relative overflow-hidden w-full h-[105vh] rounded-b-3xl bg-gradient-to-br from-slate-400 via-slate-200 to-slate-50 shadow-sm-light`}
      >
        <div className="book-bg hidden md:block absolute top-0 bottom-0 left-0 right-0"></div>
        <div className="relative h-full flex items-center justify-center">
          <div className="absolute top-1/4 left-0 w-full h-full flex flex-col items-center">
            <h1 className="mb-2 text-center text-5xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              ReadPDF.io
            </h1>
            <h2 className="mb-8 text-center text-3xl tracking-tight font-bold text-gray-900 dark:text-white">
              Upload your PDFs and listen to them
              <br />
              anytime, anywhere now!
            </h2>
            <WaveformPlayerComponent badge="subtropical-climate.pdf" />
            <div className="flex gap-3">
              <Button type="contrast" className="w-max ripple"
                click={() =>
                  handleCreatePriceSession({
                    price: "price_1QRfO5FJ3gpTYLFurNywk6Fw",
                    mode: "sub",
                    trackEvent: () => {
                      // @ts-ignore
                      window.fbq("track", "Subscribe", {
                        subscription_id: "price_1QRfO5FJ3gpTYLFurNywk6Fw",
                      });
                    },
                  })
                }
              >
                <span className="block text-md mb-0">Try Now</span>
                <span className="block text-[0.8em] font-extralight ml-2">+ 7-day free trial</span>
              </Button>

              <Link to="#samples">
                <Button type="normal" className="block w-max">More Samples 🎧</Button>
              </Link>
            </div>
            <RatingSection />
          </div>
        </div>
      </section>

      <section className="bg-body rounded-xl" id="features">
        <div className="bg-gray-50 font-sans leading-normal tracking-normal">
          <div className="container mx-auto px-4 pb-16 relative -top-5 ">
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:${getActiveFeatureCols} gap-8`}>
              {featureFlags.images_processing && <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-14 w-14 gradient-bg text-slate-900 rounded-full mx-auto mb-2 feature-icon">
                  <BsImageFill size={40} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Listen to your Images{" "}
                  <span className="relative -top-[1.5px]">
                    <Badge bgColor="bg-slate-500">AI</Badge>
                  </span>
                </h2>
                <p className="text-gray-600">
                  Hear the visual information as it is on the page, without
                  having to open your phone and look it up!
                </p>
              </div>}
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-14 w-14 gradient-bg text-slate-900 rounded-full mx-auto mb-2 feature-icon">
                  <FaMicrophoneAlt size={40} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Realistic Voice
                </h2>
                <p className="text-gray-600">
                  Natural and non-irritating voice, as if someone is reading it.
                </p>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-14 w-14 gradient-bg text-slate-900 rounded-full mx-auto mb-2 feature-icon">
                  <FaLanguage size={40} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Speaking Languages
                </h2>
                <p className="text-gray-600">
                  For your PDFs, helping you go places.
                </p>
                <div className="flex gap-1 justify-center mt-2">
                  <Flag country="uk" size={30} />
                  <Flag country="es" size={30} />
                  <Flag country="de" size={30} />
                  <Flag country="fr" size={30} />
                  <Flag country="pt" size={30} />
                  <Flag country="nl" size={30} />
                  <Flag country="ro" size={30} />
                </div>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition duration-500 hover:scale-105">
                <div className="flex items-center justify-center h-14 w-14 gradient-bg text-slate-900 rounded-full mx-auto mb-2 feature-icon">
                  <FaSquarePen size={40} />
                </div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  Write down the important stuff
                </h2>
                <p className="text-gray-600">
                  Leave notes among the audio file to remember the important parts.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="bg-body rounded-xl mt-20 mb-40">
          <div className="w-full lg:w-1/2 p-4 mx-auto relative">
            <div className="absolute -bottom-10 left-20 w-10/12 h-96 bg-orange-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            <div className="absolute -top-10 left-20 w-10/12 h-96 bg-orange-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
            <div className="relative overflow-hidden rounded-lg">
              <video controls src="/readpdfio-video-2.mp4" itemType="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section> */}
        <div className="flex items-center justify-center" id="samples">
          <div className="relative py-16 pb-20 rounded-3xl">
            <div className="absolute bottom-0 left-20 w-2/3 h-72 bg-orange-50 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-4000"></div>
            <div className="absolute top-0 left-20 w-2/3 h-72 bg-orange-50 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-4000"></div>
            <h2 className="mb-8 text-center text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Hear me out 👇
            </h2>
            <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
              <PdfPlayerComponent
                pdfName="agriculture-lecture-2.pdf"
                songIndex={1}
              >
                <p className="mt-4 text-gray-700 font-serif">
                  Agricultural analysis involves the{" "}
                  <span className="bg-yellow-100">
                    evaluation of soil, water, crops, and farming practices
                  </span>{" "}
                  to optimize agricultural productivity and sustainability. It
                  includes soil testing for nutrient levels, pH, and moisture
                  content to determine the best crop choices and fertilization
                  methods. Crop analysis monitors{" "}
                  <span className="bg-yellow-100">
                    plant health, growth patterns, and yields
                  </span>
                  , while water analysis assesses quality and irrigation
                  efficiency. Additionally, agricultural analysis examines the
                  impact of farming practices on the environment, helping
                  farmers{" "}
                  <span className="bg-yellow-100">
                    {" "}
                    make informed decisions to enhance crop production, reduce
                    waste, and promote sustainable practices
                  </span>
                  . This scientific approach is important for improving food
                  security and supporting sustainable agriculture.
                </p>
              </PdfPlayerComponent>
              {featureFlags.images_processing &&
                <PdfPlayerComponent pdfName="DNA_RNA.pdf" songIndex={2}>
                  <img src={"sample-2-pdf-image.png"} alt="DNA structures" />
                  <p className="mt-4 text-gray-800">
                    DNA (Deoxyribonucleic acid) is the molecule that carries
                    genetic information in living organisms, encoding instructions
                    for the development, functioning, and reproduction of cells.
                    RNA (Ribonucleic acid) is a single-stranded molecule that
                    plays a critical role in translating DNA’s genetic code into
                    proteins. RNA also has functions in gene regulation and is
                    essential in processes like transcription and translation.{" "}
                    <br />
                  </p>
                </PdfPlayerComponent>
              }
              <PdfPlayerComponent
                pdfName="Flamenco.pdf "
                songIndex={3}
                trailingBadge="Spanish"
              >
                <p className="mt-4 text-gray-700 font-serif">
                  La flamenco es una expresión artística profundamente arraigada
                  en la cultura española, especialmente en las regiones de
                  Andalucía, Extremadura y Murcia. Esta tradición, que combina
                  música, canto y danza, se ha transmitido de generación en
                  generación, convirtiéndose en un símbolo de la identidad
                  española.
                </p>
                <p className="mt-4 text-gray-700 font-serif">
                  El flamenco se caracteriza por su intensidad emocional y la
                  complejidad de sus ritmos. Los elementos principales del
                  flamenco incluyen el "cante" (canto), el "toque" (guitarra) y
                  el "baile" (danza). Cada uno de estos componentes se une para
                  crear una experiencia artística única que refleja las
                  alegrías, penas y pasiones de la vida.
                </p>
                <p className="mt-4 text-gray-700 font-serif">
                  El flamenco ha evolucionado con el tiempo, incorporando
                  influencias de otras culturas, pero siempre manteniendo su
                  esencia tradicional. Hoy en día, el flamenco es reconocido
                  como Patrimonio Cultural Inmaterial de la Humanidad por la
                  UNESCO, y continúa siendo una parte vital de la cultura y el
                  arte de España.{" "}
                </p>
              </PdfPlayerComponent>
            </div>
          </div>
        </div>
      </section>

      <section
        className="faq-pattern bg-white dark:bg-gray-900 rounded-xl mt-10 shadow"
        id="faq"
      >
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <h2 className="mb-8 text-center text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Frequently asked questions
          </h2>
          {FAQSection()}
        </div>
      </section>

      {!authUser && (
        <>
          <section
            id="pricing"
            className="flex flex-col md:flex-row rounded-xl mt-10 pb-10 overflow-hidden"
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center">
                <h2 className="mb-8 text-center text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Pricing
                </h2>
              </div>
              <div className="mt-16 flex flex-col md:flex-row gap-8">
                <PricingSectionComponent>
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div>
                      <h3 className="inline-flex px-4 py-1 rounded-full text-xs font-semibold tracking-wide uppercase border outline-2 border-orange-700 text-orange-700">
                        Pay Monthly
                      </h3>
                    </div>
                    <div className="mt-1 flex items-baseline text-4xl font-extrabold">
                      {activePrice().monthly}/mo
                    </div>
                    <p className="mt-2 text-xl text-gray-700">
                      Paid monthly, 7-day free trial
                    </p>
                    <PricingFeaturesListComponent>
                      <Feature>Try for free</Feature>
                      <Feature>Unlimited PDF uploads</Feature>
                      <Feature>Available on All Your Devices</Feature>
                      <Feature>High-quality MP3 conversion</Feature>
                      <Feature>Share converted files</Feature>
                      <Feature><>Text <span className="bg-yellow-100 text-black">highlighting</span></></Feature>
                      {featureFlags.images_processing &&
                        <Feature>AI Description of images and charts 💥</Feature>
                      }
                      <Feature>Take Notes</Feature>
                      <Feature>Cancel anytime</Feature>
                    </PricingFeaturesListComponent>
                  </div>
                  <div className="px-6 pt-6 pb-8 sm:p-10 sm:pt-6">
                    <Button className="ripple px-6 w-full"
                      type="contrast" click={() =>
                        handleCreatePriceSession({
                          price: "price_1QRfO5FJ3gpTYLFurNywk6Fw",
                          mode: "sub",
                          trackEvent: () => {
                            // @ts-ignore
                            window.fbq("track", "Subscribe", {
                              subscription_id: "price_1QRfO5FJ3gpTYLFurNywk6Fw",
                            });
                          },
                        })
                      }>
                      Try Now For Free!
                    </Button>
                  </div>
                </PricingSectionComponent>
                <PricingSectionComponent>
                  <div className="px-6 py-8 sm:p-10 sm:pb-6">
                    <div>
                      <h3 className="inline-flex px-4 py-1 rounded-full text-xs font-semibold tracking-wide uppercase bg-gradient-to-r from-yellow-300 to-orange-400 text-yellow-900">
                        One Time Payment
                      </h3>
                    </div>
                    <div className="mt-1 flex gap-1 items-baseline text-4xl font-extrabold">
                      {activePrice().original}
                    </div>
                    <p className="mt-2 text-xl text-gray-700">Paid once</p>
                    <PricingFeaturesListComponent>
                      <Feature>Try for free</Feature>
                      <Feature>Unlimited PDF uploads</Feature>
                      <Feature>Available on All Your Devices</Feature>
                      <Feature>High-quality MP3 conversion</Feature>
                      <Feature>Share converted files</Feature>
                      <Feature><>Text <span className="bg-yellow-100 text-black">highlighting</span></></Feature>
                      {featureFlags.images_processing &&
                        <Feature>AI Description of images and charts 💥</Feature>
                      }
                      <Feature>Take Notes</Feature>
                      <Feature>All Future Updates</Feature>
                    </PricingFeaturesListComponent>
                  </div>
                  <div className="px-6 pt-6 pb-8 sm:p-10 sm:pt-6">
                    <Button className="ripple px-6 w-full"
                      type="contrast" click={() =>
                        handleCreatePriceSession({
                          price: "price_1QRfK3FJ3gpTYLFuc4pNDqTI",
                          mode: "one",
                          trackEvent: () => {
                            // @ts-ignore
                            window.fbq("track", "Subscribe", {
                              subscription_id: "price_1QRfK3FJ3gpTYLFuc4pNDqTI",
                            });
                          },
                        })
                      }>
                      Get Now!
                    </Button>
                  </div>
                </PricingSectionComponent>
              </div>
            </div>
          </section>

          <JumbotronSignUpComponent />
        </>
      )}
    </>
  );
};

export default HomePage;
