import Amplitude from "amplitudejs";
import { Dispatch, SetStateAction, useState } from "react";
import { Note } from "../../../types";
import { IoIosClose } from "react-icons/io";
import { FaExpandAlt } from "react-icons/fa";


type NoteInputComponentProps = {
  toggle: boolean;
  setToggle: Dispatch<SetStateAction<boolean>>;
  newNoteHandler: (note: Note) => void;
};

export const NoteInputComponent = ({ toggle, setToggle, newNoteHandler }: NoteInputComponentProps) => {
  const [noteText, setNoteText] = useState("");

  if (!toggle) {
    return <></>;
  }

  function handleAddNote() {
    if (!noteText.trim()) return;

    const currentTime = Amplitude.getSongPlayedSeconds();
    const duration = Amplitude.getSongDuration();

    const positionPercent = (currentTime / duration) * 100;

    const newNote: Note = {
      id: Date.now().toString(),
      noteText: noteText,
      timeStamp: currentTime,
      markPostion: positionPercent,
    };

    newNoteHandler(newNote);
    setNoteText("");
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === " ") {
      e.stopPropagation();
    }
  }

  return (
    <div className="absolute right-0 -top-[135px] z-50 my-4 text-base bg-white rounded-lg  shadow-xl dark:bg-gray-700">
      <div className="px-4 py-1 flex align-middle justify-between border-b" role="none">
        <p className="text-sm text-gray-900 dark:text-white">
          New note
        </p>
        <div className="flex gap-2 justify-center">
          {/* <span className="has-tooltip">
            <span className="custom-tooltip">Preiview</span>
            <FaExpandAlt className="cursor-pointer mt-[2px]" size={11} />
          </span> */}
          <IoIosClose className="cursor-pointer" onClick={() => setToggle(false)} size={16} />
        </div>
      </div>
      <div role="none" className="flex flex-col gap-2 px-4 pb-2 mt-2">
        <textarea
          className="flex-1 border border-gray-300 rounded px-2 py-1 text-sm"
          placeholder="Type something..."
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          onKeyDown={handleKeyDown}>
        </textarea>
        <button
          onClick={handleAddNote}
          className="px-2 py-1 bg-gray-700 text-white text-sm rounded hover:bg-gray-800 active:outline active:outline-1 active:outline-offset-1 active:outline-gray-600"
        >
          Add
        </button>
      </div>
    </div>);
};
