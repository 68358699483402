
import { useEffect, useState } from "react";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { useResetScroll } from "../../hooks/reset-scroll.hook";
import { useTitle } from "../../hooks/useTitle.hook";
import { ActiveSubscriptionTypes, DatabaseUserNote } from "../../types";

import toast from "react-hot-toast";
import { FaExpandAlt } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { TrackPageSubscribePromptComponent } from "../../components/player-track-page/track-page-subscribe-prompt/track-page-subscribe-prompt.component";
import useDatabaseNote from "../../hooks/database-note.hook";
import { formatSecondsToMMSS } from "../../utils/time";
import NoteModal from "../../components/note-modal/note-modal.component";
import { FaPlay } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { encodeString } from "../../utils/crypto-utils";

export type DirtyNote = DatabaseUserNote & { trackId: string };

const NotesPage = () => {
  const { user, subscriptionStatus } = useCombinedAuthHook();
  const [notes, setNotes] = useState<DirtyNote[]>([]);
  const [noteToShow, setNoteToShow] = useState<DirtyNote | undefined>(undefined);
  const [updateNotes, setUpdateNotes] = useState(false);
  const { deleteNote, getNotesForUser } = useDatabaseNote();
  const navigate = useNavigate();

  useTitle("Notes");
  useResetScroll();

  const updateNotesForUser = () => {
    getNotesForUser().then((notes) => {
      if (Object.keys(notes).length === 0) return;

      const trackIds = Object.keys(notes);
      const notesWithKeys = trackIds.flatMap((trackId) => {
        return Object.keys(notes[trackId]).map((noteId) => {
          return {
            ...notes[trackId][noteId],
            id: noteId,
            trackId: trackId,
          };
        });
      });
      setNotes(notesWithKeys);
    });
  };

  useEffect(() => {
    if (!user) return;

    updateNotesForUser();
  }, [user]);

  useEffect(() => {
    if (updateNotes) {
      setTimeout(() => {
        updateNotesForUser();
        setUpdateNotes(false);
      }, 100);
    }
  }, [updateNotes]);

  const handleRemoveNote = async (authorId: string, fileId: string, id: string) => {
    if (!fileId || !authorId || !id) {
      toast.error("Cannot remove the note now. Please, try again.");
      return;
    };

    try {
      await deleteNote(authorId, fileId, id);
      setNotes((prev) => prev.filter((note) => note.id !== id));
    } catch (e) {
      toast.error("Error removing note. Please try again.");
      return;
    }

    toast.success("Note deleted", {
      duration: 500,
    });
  };

  const takeMeToTrackPage = (note: DirtyNote) => {
    const targetUrl = `/app/track/${encodeString(note.trackAuthorId)}/${note.trackId}?mark=${note.timeStamp}`;
    navigate(targetUrl);
  };

  return (
    <>
      {!ActiveSubscriptionTypes.includes(subscriptionStatus) && (
        <TrackPageSubscribePromptComponent />
      )}
      <div className="flex flex-col items-center h-fit md:h-screen sm:p-4">
        <div className={`min-h:calculated-height-${ActiveSubscriptionTypes.includes(subscriptionStatus) ? 'subscribed' : 'unsubscribed'} w-full lg:max-w-screen-lg border bg-[#f3f3f3] rounded-lg p-2 sm:p-6 flex flex-col`}>
          <h1 className="bold text-center text-xl">Notes</h1>
          <div className="mt-4">
            {notes.length === 0 ? (
              <p className="text-gray-500 mt-3">No notes yet.</p>
            ) : (
              <>
                {notes
                  .sort((a, b) => a.trackId.localeCompare(b.trackId))
                  .map((note, idx) => (
                    <div key={idx} className="mt-3 p-3 border bg-white border-gray-300 rounded-lg shadow-sm cursor-pointer flex justify-between">
                      <div className="w-10/12 px-2 rounded-lg overflow-hidden whitespace-nowrap truncate text-ellipsis flex-grow">
                        <span className="text-gray-700">{note.noteText}</span>
                        <div className="text-xs text-gray-700 mb-1">
                          At: {formatSecondsToMMSS(note.timeStamp)}
                        </div>
                      </div>
                      <div className="flex gap-2 align-middle justify-center items-center pl-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            takeMeToTrackPage(note);
                          }}
                          className="bg-white border border-gray-700 hover:bg-gray-100 active:bg-gray-200 text-white px-2 py-1 rounded h-fit w-fit has-tooltip">
                          <span className="custom-tooltip -ml-9">Play the track</span>
                          <FaPlay className="text-sky-600" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setNoteToShow(note);
                          }}
                          className="bg-white border border-gray-700 hover:bg-gray-100 active:bg-gray-200 text-white px-2 py-1 rounded h-fit w-fit has-tooltip">
                          <span className="custom-tooltip">Expand</span>
                          <FaExpandAlt className="text-gray-700" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveNote(note.trackAuthorId, note.trackId, note.id);
                          }}
                          className="bg-red-500 hover:bg-red-600 border border-red-600 active:bg-red-700 text-white px-2 py-1 rounded h-fit w-fit has-tooltip">
                          <span className="custom-tooltip">Delete</span>
                          <FiTrash2 />
                        </button>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      <NoteModal activeNote={noteToShow} removeActiveNote={() => setNoteToShow(undefined)} takeMeToTrackPage={takeMeToTrackPage} updateNotes={(b) => setUpdateNotes(b)} />
    </>
  );
};

export default NotesPage;
