import { useAuth } from "./auth.hook";
import { useDatabaseUser } from "./database-user.hook";
import { useStripeCheckSubscriptionStatus } from "./stripe-check-subscription.hook";

export const useCombinedAuthHook = () => {
    const { user, logout, isLoading: authLoading, authToken } = useAuth();
    const { databaseUser, loading: dbUserLoading } = useDatabaseUser();
    const sessionId = databaseUser.stripeSessionId ?? '';
    const { status: subscriptionStatus, isLoading: stripeLoading, error } = useStripeCheckSubscriptionStatus(sessionId, authToken);

    return {
        user,
        logout,
        databaseUser,
        subscriptionStatus,
        error,
        loading: authLoading || dbUserLoading || stripeLoading,
        authToken: authToken
    };
};