import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaPlay } from "react-icons/fa6";
import { tryGetTrackByUserUidAndFileId } from "../../hooks/database-file";
import useDatabaseNote from "../../hooks/database-note.hook";
import { DirtyNote } from "../../pages/notes/notes.page";
import { UserTracks } from "../../types";
import { formatSecondsToMMSS } from "../../utils/time";

type NoteModalProps = {
  activeNote: DirtyNote | undefined;
  removeActiveNote: () => void;
  takeMeToTrackPage: (note: DirtyNote) => void;
  updateNotes: (b: boolean) => void;
};

const NoteModal = ({ activeNote, removeActiveNote, takeMeToTrackPage, updateNotes }: NoteModalProps) => {
  const { updateNote, deleteNote } = useDatabaseNote();
  const [editMode, setEditMode] = useState(false);
  const [noteContent, setNoteContent] = useState(activeNote?.noteText ?? "");
  const [track, setTrack] = useState<UserTracks | null>(null);

  useEffect(() => {
    const fetchTrackData = () => {
      tryGetTrackByUserUidAndFileId(activeNote?.trackAuthorId ?? "", activeNote?.trackId ?? "").then((track) => {
        if (track) {
          setTrack(track);
        }
      });
    };

    if (!activeNote) {
      return;
    }

    setEditMode(false);
    setNoteContent(activeNote?.noteText ?? "");
    fetchTrackData();
  }, [activeNote]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        removeActiveNote();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  if (!activeNote) {
    return null;
  }

  const handleSave = async () => {
    try {
      await updateNote(activeNote.trackId, activeNote.id, { noteText: noteContent });
      updateNotes(true);
    } catch (e) {
      console.error(e);
      toast.error("Error updating note. Please try again.");
      return;
    }
    toast.success("Note updated!");
    setEditMode(false);
  };

  const onDelete = async () => {
    deleteNote(activeNote.trackAuthorId, activeNote.trackId, activeNote.id).then(() => {
      updateNotes(true);
      removeActiveNote();
      toast.success("Note deleted");
    }
    ).catch(() => {
      toast.error("Error removing note. Please try again.");
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setNoteContent(activeNote?.noteText ?? "");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[99]">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full overflow-hidden">
        <div className="flex justify-between mb-4 border-b border-gray-300 pb-2 p-4 bg-gray-100">
          {track && (
            <div className="flex space-x-2">
              <img
                src={`data:image/jpeg;base64,${track.coverPage}`}
                alt="Track thumbnail"
                className="h-20 border rounded-lg"
              />
              <div>
                <h3 className="text-lg">{track.trackUid}</h3>
                <p className="text-xs text-gray-700 mb-1">
                  At: {formatSecondsToMMSS(activeNote.timeStamp)}
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    takeMeToTrackPage(activeNote);
                  }}
                  className="bg-white border border-gray-700 hover:bg-gray-100 active:bg-gray-200 text-black px-2 py-1 rounded h-fit w-fit">
                  <FaPlay className="text-sky-600 inline" /> Play there
                </button>
              </div>
            </div>

          )}
          <button
            onClick={() => removeActiveNote()}
            className="text-gray-500 hover:text-gray-800 text-xl leading-none self-baseline"
          >
            &times;
          </button>
        </div>
        <div className="p-4">

          {editMode ? (
            <textarea
              className="w-full h-40 border border-gray-300 rounded p-2"
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
            />
          ) : (
            <div className="max-w-lg h-40 overflow-y-auto whitespace-pre-wrap break-words p-2">
              {noteContent}
            </div>
          )}

          <div className="mt-4 flex justify-end space-x-2">
            {editMode ? (
              <>
                <button
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                >
                  Save
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="bg-gray-300 text-gray-700 px-3 py-1 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleEditClick}
                  className="text-black border border-gray-700 px-3 py-1 rounded hover:bg-gray-100 active:bg-gray-200"
                >
                  Edit
                </button>
                <button
                  onClick={onDelete}
                  className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default NoteModal;
