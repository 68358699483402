// import * as Amplitude from "amplitudejs";
import Amplitude from "amplitudejs";
import throttle from "lodash/throttle";
import { useEffect, useRef, useState } from "react";
import { MdOutlineNoteAdd } from "react-icons/md";
import { SlSpeedometer } from "react-icons/sl";
import { TbRewindBackward10, TbRewindForward10 } from "react-icons/tb";
import useAmplitudePlayer from "../../hooks/amplitude-player.hook";
import { useCombinedAuthHook } from "../../hooks/combined-auth.hook";
import { tryUpdateTimestamp } from "../../hooks/database-file";

import { FaPause, FaPlay } from "react-icons/fa6";
import { useRecoilState } from "recoil";
import { showNotesState } from "../../state/show-notes-pane.state";
import { Note } from "../../types";
import { NoteInputComponent } from "./note-input/note-input.component";
import { NoteMark } from "./note-mark/note-mark.component";
import PlaybackSpeedComponent from "./playback-speed/playback-speed.component";
import "./player-track-page.styles.scss";

export type MediaSessionMeta = {
  title: string;
}

type PlayerTrackPageProps = {
  author: string;
  url: string;
  trackUid: string;
  startTime: number;
  onTimeUpdate?: (time: number) => any;
  onAddNote: (note: Note) => void;
  onRemoveNote: (id: string) => void;
  notes: Note[];
  mediaSessionInformation?: MediaSessionMeta | undefined;
};

const PlayerTrackPageComponent = (props: PlayerTrackPageProps) => {
  const [amplitudePlaybackSpeedRate, setAmplitudePlaybackSpeedRate] = useState<number>(1);
  const { user } = useCombinedAuthHook();
  const [playbackSpeedToggler, setPlaybackSpeedToggler] = useState(false);
  const [noteInputToggler, setNoteInputToggler] = useState(false);
  const sliderRef = useRef<HTMLInputElement>(null);
  const [isPanelOpen, setIsPanelOpen] = useRecoilState(showNotesState);

  let lastUpdateTime = 0;

  const throttledWriteToDatabase = throttle((time) => {
    if (time > 0) {
      tryUpdateTimestamp(user.uid, props.author, props.trackUid, time);
    }
  }, 1500);

  const initPLayer = useAmplitudePlayer({
    debug: false,
    bindings: {
      // 32: "play_pause",
    },
    callbacks: {
      timeupdate: function () {
        const currentTime = Amplitude.getSongPlayedSeconds();

        props.onTimeUpdate && props.onTimeUpdate(currentTime);

        if (
          Math.floor(currentTime) % 3 === 0 &&
          Math.floor(currentTime) !== lastUpdateTime
        ) {
          lastUpdateTime = Math.floor(currentTime);
          throttledWriteToDatabase(lastUpdateTime);
        }

        let percentage = Amplitude.getSongPlayedPercentage();

        if (isNaN(percentage)) {
          percentage = 0;
        }

        sliderRef.current!.style.backgroundSize = percentage + "% 100%";
      },
    },
    songs: [{
      url: props.url ?? "",
    }],
  });

  const initMediaMeta = () => {
    if (navigator && navigator.hasOwnProperty('mediaSession')) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: props.mediaSessionInformation?.title ?? 'Your PDF Track',
        artist: 'ReadPDF.io',
        artwork: [
          { src: '/icon-500x400.svg', sizes: '500x400', type: 'image/svg' },
        ]
      });

      navigator.mediaSession.setActionHandler('play', () => Amplitude.play());
      navigator.mediaSession.setActionHandler('pause', () => Amplitude.pause());
      navigator.mediaSession.setActionHandler('seekbackward', () => rewind(-10));
      navigator.mediaSession.setActionHandler('seekforward', () => rewind(10));
    }
  };

  useEffect(() => {
    initPLayer();
    initMediaMeta();
  }, []);

  useEffect(() => {
    if (props.startTime > 0) {
      setTimeout(() => {
        const duration = Amplitude.getSongDuration(0);
        const targetTime = props.startTime;
        Amplitude.setSongPlayedPercentage((targetTime / duration) * 100);
      }, 200);
    }
  }, [props.startTime]);

  function setPlaybackSpeedTo(rate: number) {
    setPlaybackSpeedToggler(false);
    Amplitude.setPlaybackSpeed(rate);
    setAmplitudePlaybackSpeedRate(rate);
  }

  function rewind(target: number) {
    const elapsed = Amplitude.getSongPlayedSeconds();

    if (elapsed + target > 0) {
      Amplitude.skipTo(elapsed + target, 0);
    } else {
      Amplitude.skipTo(0, 0);
    }
  }

  function handleMarkClick() {
    setIsPanelOpen(true);
  }

  function handleAddNote(note: Note) {
    props.onAddNote(note);
    setNoteInputToggler(false);
  }

  return (
    <section id="in-page-player" className="p-3 lg:p-0 rounded-lg border lg:rounded-full shadow-lg w-full mx-auto pr-3 bg-[#fff]">
      {/*https://codepen.io/Rafail-Ruff-Askerov/pen/yLRYoZP*/}
      <div className="relative mx-auto flex flex-col-reverse lg:flex-row justify-between w-full gap-4 lg:gap-1">
        <div className="bg-white lg:bg-[#f3f3f3] pl-3 border-none lg:border-r rounded-full pr-3 h-control-panel w-full lg:w-[350px] flex flex-none bg-control-panel-light-background items-center justify-between relative dark:bg-control-panel-dark-background">
          <div
            className="cursor-pointer"
            onClick={() => setPlaybackSpeedToggler(!playbackSpeedToggler)}
          >
            <div className="relative top-[9px] has-tooltip">
              <span className='custom-tooltip'>Playback Speed</span>
              <SlSpeedometer size={24} color="#a2a4a9" />
            </div>
            <div className="w-[35px] relative bg-slate text-[0.6em] p-[2px] left-[14px] bg-slate-600 text-white rounded-lg text-center">
              {amplitudePlaybackSpeedRate}x
            </div>
          </div>
          <div className="cursor-pointer has-tooltip" onClick={() => rewind(-10)}>
            <span className='custom-tooltip'>Rewind back</span>
            <TbRewindBackward10 size={24} color="#a2a4a9" />
          </div>
          <div className="cursor-pointer amplitude-play-pause w-14 h-14 rounded-full bg-sky-500 hover:bg-sky-600 border border-play-pause-light-border shadow-xl flex items-center justify-center dark:bg-play-pause-dark-background dark:border-play-pause-dark-border">
            <FaPlay id="play-icon" size={25} color="#FFF" />
            <FaPause id="pause-icon" size={25} color="#FFF" />
          </div>
          <div className="cursor-pointer has-tooltip" onClick={() => rewind(10)}>
            <span className='custom-tooltip'>Rewind forward</span>
            <TbRewindForward10 size={24} color="#a2a4a9" />
          </div>
          <div className="cursor-pointer" onClick={() => setNoteInputToggler(!noteInputToggler)}>
            <div className="has-tooltip">
              <span className='custom-tooltip'>Add a note</span>
              <MdOutlineNoteAdd size={24} color="#a2a4a9" />
            </div>
          </div>
          <PlaybackSpeedComponent toggle={playbackSpeedToggler} setPlaybackSpeedTo={(x) => setPlaybackSpeedTo(x)} />
          <NoteInputComponent setToggle={setNoteInputToggler} toggle={noteInputToggler} newNoteHandler={(note) => handleAddNote(note)} />
        </div>
        <div className="flex-grow flex flex-row px-3 justify-between items-center gap-1">
          <div className="amplitude-current-time text-xs font-sans tracking-wide font-medium text-sky-500 dark:text-sky-300"></div>
          <div className="relative w-full mx-2">
            <input
              type="range"
              id="song-percentage-played"
              className="amplitude-song-slider z-30 w-full h-2 appearance-none bg-gray-200 rounded-full cursor-pointer pointer-events-auto"
              step=".1"
              ref={sliderRef}
            />

            <div className="absolute top-[10px] left-0 w-full h-2 pointer-events-auto z-0">
              {props.notes.map((note, index) => (
                <NoteMark
                  key={index}
                  markPostion={note.markPostion}
                  noteText={note.noteText}
                  timeStamp={note.timeStamp}
                  onMarkClick={handleMarkClick}
                />
              ))}
            </div>
          </div>

          <div className="amplitude-duration-time text-xs font-sans tracking-wide font-medium text-gray-500"></div>
        </div>
      </div>
    </section>
  );
};

export default PlayerTrackPageComponent;
