import './button.styles.scss';

const ButtonTypes = (<T extends string[]>(...o: T) => o)('normal', 'contrast', 'ghost', 'flat', 'secondary');

type ButtonProps = {
  children: React.ReactNode;
  type?: typeof ButtonTypes[number];
  click?: (e: any) => void;
  className?: string;
  disabled?: boolean;
};

const Button = ({ children, type = 'normal', className, click, disabled }: ButtonProps) => {
  return (
    <button disabled={disabled} onClick={(e: any) => click && click(e)}
      className={`custom-button-component type-${type} ${className}`}>
      {children}
    </button>
  );
};

export default Button;